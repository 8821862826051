export const getSplittedValuesFromFS = featureSwitchValue => {
  if (!featureSwitchValue) return [];
  return featureSwitchValue?.split(',') || [];
};

export const isFeatureSwitchEnabledForValue = (value, featureSwitchValue) => {
  const fsValueString = featureSwitchValue?.toString();
  const fsValueIsBoolean =
    fsValueString === 'false' || fsValueString === 'true';

  return fsValueIsBoolean
    ? fsValueString === 'true'
    : getSplittedValuesFromFS(featureSwitchValue).includes(value);
};

export const isEmptyObject = object => {
  if (!object) return true;

  if (typeof object === 'object') {
    return Object.keys(object).length === 0;
  }

  return false;
};

/**
 * Parse the remote configurations of type JSON
 * Feature switches are also a remote config but we use
 * only of types boolean, string and number.
 * The remote config will be use to config our components behaviour
 * @param {*} remoteConfig value configured in remote config in firebase
 * @param {*} defaultValue default value to be returned in case no config is found
 * @returns Object
 */
export const getRemoteConfigJSON = (remoteConfig, defaultValue) => {
  try {
    const JSONConfig = JSON.parse(remoteConfig);
    return !isEmptyObject(JSONConfig) ? JSONConfig : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};
