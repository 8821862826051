const credentialKey = 'current_user';

const getCurrentUserInfo = () => {
  const creadentials = localStorage.getItem(credentialKey);
  return JSON.parse(creadentials) || {};
};

const storeCurrentUserInfo = currentUserInfo => {
  localStorage.setItem(credentialKey, JSON.stringify(currentUserInfo));
};

const removeCurrentUserInfo = () => {
  localStorage.removeItem(credentialKey);
};

/**
 * Get current credentail and update with new info
 * @param {Object} infoToUpdate
 */
const updateCurrentUserInfo = infoToUpdate => {
  storeCurrentUserInfo({
    ...getCurrentUserInfo(),
    ...infoToUpdate
  });
};

export {
  getCurrentUserInfo,
  storeCurrentUserInfo,
  removeCurrentUserInfo,
  updateCurrentUserInfo
};
