import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Box,
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import { GoogleSignIn } from '@loggi/authentication';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import { isCognitoUserAssociatedWithLoggiUser } from 'infra-operations/auth/cognito';
import { ReactComponent as LoggiIcon } from 'view/assets/svg/loggi.svg';
import { pages } from 'routes';
import deviceValidation from 'infra-operations/services/device-validation';
import { removeCurrentUserInfo } from 'infra-operations/storage/credential';
import DeviceValidationError from 'view/pages/device-validation-error';
import LoadingTemplate from 'view/templates/loading';
import { onboardingPageTexts, useStyles } from './constants';

function UserNotLoggedIn() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <Box pt={isDesktop ? 4.5 : 2.5}>
        <Typography variant="h3">
          <Box component="span" fontWeight="fontWeightBold">
            {onboardingPageTexts.title}
          </Box>
        </Typography>
      </Box>
      <Box pt={1.5}>{onboardingPageTexts.subtitle}</Box>
    </Box>
  );
}

function UserNotLoggedInButtons() {
  const classes = useStyles();

  const onHelpMe = event => {
    event.preventDefault();
    window.open(onboardingPageTexts.helpLink);
  };

  return (
    <Box>
      <GoogleSignIn />
      <Box pt={2.5} pb={2.5} textAlign="center">
        <Typography variant="body1">
          {onboardingPageTexts.help}{' '}
          <Box component="a" className={classes.link} onClick={onHelpMe}>
            {onboardingPageTexts.helpAction}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}

function CognitoNotAssociated({ authenticatedUser }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <Box pt={isDesktop ? 4.5 : 2.5}>
        <Typography variant="h4">{onboardingPageTexts.titleCognito}</Typography>
      </Box>
      <Box pt={1.5}>
        <Typography variant="subtitle2">
          {`${authenticatedUser.name?.split(' ')[0]}${
            onboardingPageTexts.notFoundAccount
          }`}{' '}
          <Box component="span" fontWeight="fontWeightBold">
            {authenticatedUser.email}
          </Box>
          {onboardingPageTexts.notFoundAccountAction}
        </Typography>
      </Box>
    </Box>
  );
}

CognitoNotAssociated.propTypes = {
  authenticatedUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired
};

function CognitoNotAssociatedButtons({
  logout,
  handleCreateAccountButtonClick
}) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={() => history.push(pages.login)}
      >
        {onboardingPageTexts.loginAction}
      </Button>
      <Box pt={2} pb={3} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          onClick={handleCreateAccountButtonClick}
        >
          {onboardingPageTexts.createAccount}
        </Button>
      </Box>
      <Box pb={2.5} textAlign="center">
        <Typography variant="body1">
          {onboardingPageTexts.wrongEmail}{' '}
          <Box component="a" className={classes.link} onClick={logout}>
            {onboardingPageTexts.logoutAction}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}

CognitoNotAssociatedButtons.propTypes = {
  logout: PropTypes.func.isRequired,
  handleCreateAccountButtonClick: PropTypes.func.isRequired
};

export default function OnboardingPage({
  createAccountPath,
  onSuccessLoginRedirectPath
}) {
  const theme = useTheme();
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [driverData, setDriverData] = useState(null);
  const [isUiLoading, setIsUILoading] = useState(false);
  const [deviceRegistrationError, setDeviceRegistrationError] = useState(null);
  const isDriverLoggedIn = driverData?.isAuthenticated;

  const {
    state: { authenticatedUser, loading: isCognitoLoading },
    fetchCurrentUserFromCognito,
    signOut
  } = useAmplifyAuth();

  const handleCreateAccountButtonClick = () => {
    setIsUILoading(true);
    window.location.href = createAccountPath;
  };

  const logout = async () => {
    setIsUILoading(true);
    await signOut();
  };

  const cognitoUserIsAssociated = isCognitoUserAssociatedWithLoggiUser(
    authenticatedUser
  );

  useEffect(() => {
    if (!isCognitoLoading && cognitoUserIsAssociated) {
      setIsUILoading(true);
      setDriverData(null);

      deviceValidation()
        .then(response => {
          setDriverData(response);
        })
        .catch(error => {
          removeCurrentUserInfo();

          if (error.code === 401 && Array.isArray(error.errors)) {
            const { code, email } = error.errors[0] || {};
            setDriverData({ email, isAuthenticated: false });
            setDeviceRegistrationError(code);
          } else {
            setDriverData(error);
          }
        })
        .finally(() => {
          setIsUILoading(false);
        });
    }
  }, [isCognitoLoading, cognitoUserIsAssociated]);

  useEffect(() => {
    if (isDriverLoggedIn) {
      setIsUILoading(true);

      fetchCurrentUserFromCognito()
        .then(() => {
          setIsUILoading(false);
          window.location.href = onSuccessLoginRedirectPath;
        })
        .catch(error => {
          setDriverData(error);
          setIsUILoading(false);
        });
    }
  }, [
    isDriverLoggedIn,
    fetchCurrentUserFromCognito,
    onSuccessLoginRedirectPath
  ]);

  const getStyleClasses = isScreenDesktop => {
    return isScreenDesktop
      ? {
          deviceClass: classes.headerDesktop,
          contentGridClass: classes.contentGridDesktop,
          contentTextClass: classes.contentTextDesktop
        }
      : {
          deviceClass: classes.headerMobile,
          contentGridClass: classes.contentGridMobile,
          contentTextClass: classes.contentTextMobile
        };
  };

  const { deviceClass, contentGridClass, contentTextClass } = getStyleClasses(
    isDesktop
  );
  const headerClass = `${deviceClass} ${classes.header}`;

  if (isUiLoading || isCognitoLoading) {
    return <LoadingTemplate />;
  }

  if (deviceRegistrationError) {
    return (
      <DeviceValidationError
        errorDevice={deviceRegistrationError}
        email={driverData?.email}
      />
    );
  }

  return (
    <Grid container className={classes.headerDesktop}>
      <Grid item sm={12} md={6} className={deviceClass}>
        <Box className={headerClass} />
      </Grid>
      <Grid item sm={12} md={6} className={contentGridClass}>
        <Box pt={5} px={3.5} className={contentTextClass}>
          <Box display="flex" flexGrow={1} alignItems="flex-start">
            <Box>
              <LoggiIcon height={isDesktop ? '60px' : '40px'} />
              {authenticatedUser ? (
                <CognitoNotAssociated authenticatedUser={authenticatedUser} />
              ) : (
                <UserNotLoggedIn />
              )}
            </Box>
          </Box>
          <Box pt={isDesktop ? 7.5 : 1.5}>
            {authenticatedUser ? (
              <CognitoNotAssociatedButtons
                logout={logout}
                handleCreateAccountButtonClick={handleCreateAccountButtonClick}
              />
            ) : (
              <UserNotLoggedInButtons />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

OnboardingPage.propTypes = {
  createAccountPath: PropTypes.string,
  onSuccessLoginRedirectPath: PropTypes.string
};

OnboardingPage.defaultProps = {
  createAccountPath: null,
  onSuccessLoginRedirectPath: null
};
