import React from 'react';
import ReactDOM from 'react-dom';
import 'infra/services/sentry-init';
import { setWatchPosition } from 'infra/services/geolocation';
import App from './App';
import firebaseInit from './infra/firebase/firebase-init';

firebaseInit();

setWatchPosition();

ReactDOM.render(<App />, document.getElementById('root'));
