import { makeStyles } from '@material-ui/core';
import { colors } from '@loggi/mar';

export const texts = {
  title: 'Fazer entregas é nosso negócio.',
  subtitle:
    'Os entregadores cadastrados na Loggi têm aqui uma excelente oportunidade de aumento de renda e ainda têm liberdade para gerenciar o próprio tempo e aceitar e recusar rotas quando quiserem.',
  helpMe: 'Me ajuda',
  helpMeLink:
    'https://ajuda.loggi.com/hc/pt-br/sections/115000587487-Cadastro-e-conta',
  createAccount: 'Criar minha conta',
  login: 'Já tem conta com a Loggi?',
  loginAction: 'Entrar'
};

export const useStyles = makeStyles(theme => ({
  header: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  headerDesktop: {
    background: colors.gradients.sanches
  },
  whiteText: {
    color: theme.palette.common.white
  },
  contentGrid: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflowX: 'auto',
    justifyContent: 'center'
  },
  contentText: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start'
  },
  containerPage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentPage: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  blueIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));
