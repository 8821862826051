import wretch from 'wretch';
import {
  RESPONSE_MESSAGES_BY_CODE,
  NETWORK_ERROR_MESSAGE
} from 'infra/services/constants';
import authMiddleware from './middlewares';

function translateStatusCode(status, message = '') {
  let errorMessage = '';

  if (RESPONSE_MESSAGES_BY_CODE[status]) {
    errorMessage = RESPONSE_MESSAGES_BY_CODE[status];
  } else if (message) {
    try {
      const json = JSON.parse(message);
      const { errors } = json || {};

      if (errors === undefined) {
        const REMOVE_QUOTES = new RegExp(/('|")/g);
        errorMessage = message.replace(REMOVE_QUOTES, '');
      }
    } catch (e) {
      errorMessage = message;
    }
  }

  return {
    message: errorMessage,
    status
  };
}

export default function apiWrapper() {
  return wretch()
    .url(process.env.REACT_APP_LOGGI_WEB_URL)
    .content('application/json')
    .middlewares([authMiddleware], true)
    .errorType('text')
    .catcher('Error', err =>
      Promise.reject(translateStatusCode(err.status, err.text))
    )
    .catcher('FetchError', () =>
      Promise.reject(translateStatusCode(null, NETWORK_ERROR_MESSAGE))
    )
    .catcher('__fromFetch', () =>
      Promise.reject(translateStatusCode(null, NETWORK_ERROR_MESSAGE))
    );
}
