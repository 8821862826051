import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import useStyles from '../style';

const Submit = ({ disabled, className, text, onClick }) => {
  const styles = useStyles();
  return (
    <Box className={`${className} ${styles.submitButton}`}>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        size="large"
        onClick={onClick}
        fullWidth
        disabled={disabled}
      >
        {text}
      </Button>
    </Box>
  );
};

Submit.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string
};

Submit.defaultProps = {
  disabled: false,
  className: null,
  text: null
};

export default Submit;
