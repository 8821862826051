import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import { ReactComponent as DeviceAlreadyRegisteredIcon } from 'view/assets/svg/device-already-registered.svg';
import LoadingTemplate from 'view/templates/loading';

export const alreadyRegisteredPageTexts = {
  title: 'Aparelho já está associado a outro entregador',
  subtitle:
    'Esse celular já está associado a outro entregador, caso você seja o dono desse aparelho, entre em contato com a Loggi.',
  suport: 'Fale com a gente',
  linkSuport:
    'https://ajuda.loggi.com/hc/pt-br/requests/new?ticket_form_id=160657',
  logout: 'Sair da conta'
};

export default function AlreadyRegisteredPage() {
  const [isUiLoading, setIsUILoading] = useState(false);
  const { signOut } = useAmplifyAuth();

  const logout = async () => {
    setIsUILoading(true);
    await signOut();
  };

  if (isUiLoading) {
    return <LoadingTemplate />;
  }

  return (
    <Box pt={8} p={3} height="100%" display="flex" flexDirection="column">
      <Box flexGrow={1}>
        <DeviceAlreadyRegisteredIcon />
        <Box my={1}>
          <Typography variant="h5">
            {alreadyRegisteredPageTexts.title}
          </Typography>
        </Box>
        <Typography variant="subtitle2">
          {alreadyRegisteredPageTexts.subtitle}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => window.open(alreadyRegisteredPageTexts.linkSuport)}
        >
          {alreadyRegisteredPageTexts.suport}
        </Button>
        <Box pt={2} pb={3} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            onClick={logout}
          >
            {alreadyRegisteredPageTexts.logout}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
