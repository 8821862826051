import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { Box, Typography, Divider } from '@material-ui/core';
import { pxToRem } from '@loggi/mar/src/utils';
import { formatMoney } from './util';
import {
  AcceptOfferButton,
  RoundListMarker,
  RejectOfferButton
} from './common';
import TEXT from './constants';

function OfferDetail({ offer, onAccept, onReject, hasReject }) {
  const handleAccept = () =>
    onAccept(offer.itineraryId, offer.demandId, offer.id);

  const handleReject = () => onReject();

  function isFirstOrLast(index, length) {
    return index === 0 || index === length - 1;
  }

  function getMarkerBgColor(index, length) {
    return isFirstOrLast(index, length) ? colors.blue[500] : colors.root[0];
  }

  function getMarkerTxtColor(index, length) {
    return isFirstOrLast(index, length) ? colors.root[0] : colors.blue[500];
  }

  return (
    <Box
      bgcolor={colors.root[0]}
      boxShadow={13}
      minWidth={pxToRem(300)}
      width="50%"
    >
      <Box p={3}>
        <Box textAlign="center">
          <Typography color="textSecondary">Valor da Rota</Typography>
          <Typography color="textPrimary" variant="h2">
            {formatMoney(offer.price)}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={3} maxHeight={pxToRem(300)} overflow="auto">
        {offer.waypoints.map((waypoint, index) => (
          <Box
            mb={1.5}
            key={waypoint.indexDisplay}
            display="flex"
            alignItems="center"
          >
            <Box minWidth="50px">
              {(index === 0 || index + 1 === offer.waypoints.length) && (
                <Typography variant="body1" color="textSecondary">
                  {index ? TEXT.END : TEXT.START}
                </Typography>
              )}
            </Box>
            <RoundListMarker
              bgcolor={getMarkerBgColor(index, offer.waypoints.length)}
              txtcolor={getMarkerTxtColor(index, offer.waypoints.length)}
              index={waypoint.indexDisplay}
            />
            <Box ml={1.5}>
              <Typography color="textPrimary">
                {waypoint.neighborhood}
              </Typography>
              <Typography color="textSecondary">{waypoint.city}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="space-evenly"
        textAlign="center"
        alignItems="center"
        pb={1.5}
      >
        {hasReject && (
          <Box px={1} data-testid="offer-detail-reject-button">
            <RejectOfferButton handleReject={handleReject} />
          </Box>
        )}
        <Box px={1}>
          <AcceptOfferButton handleAccept={handleAccept} />
        </Box>
      </Box>
    </Box>
  );
}

OfferDetail.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  hasReject: PropTypes.bool
};

OfferDetail.defaultProps = {
  onReject: () => null,
  hasReject: false
};

export default React.memo(OfferDetail);
