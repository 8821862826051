export default {
  OFFER_VALUE: 'Valor da Rota',
  FIRST_STOP_DISTANCE: 'Até retirada',
  FIRST_STOP_DISTANCE_ERROR: '-',
  TOTAL_DISTANCE: 'Percurso',
  START: 'Início',
  WAYPOINTS: 'pontos',
  WAYPOINT: 'ponto',
  END: 'Fim',
  BUTTON_ACCEPT_OFFER: 'Aceitar',
  BUTTON_ACCEPT_OFFER_DIRECT: 'Entendi',
  DEADLINE_COLLECT: 'Chegar no local de coleta até',
  EARN_UP_TO: 'Ganhe até',
  STOPS: 'paradas',
  TOTALS: 'totais',
  TODAY_UNTIL: 'hoje até',
  DEADLINE_COLLECT_AT: 'Chegar',
  FIRST_STOP_DISTANCE_AT: 'Retirada a ',
  TOTAL: 'Total',
  BUTTON_REJECT_OFFER: 'Rejeitar',
  BUTTON_RESERVE_OFFER: 'Reservar',
  WAYPOINT_LOAD: 'Retirar',
  WAYPOINT_UNLOAD: 'Entregar',
  WAYPOINT_LOAD_PLACE: 'lugar',
  WAYPOINT_UNLOAD_PLACE: 'local',
  WAYPOINT_LIMIT: 'até'
};
