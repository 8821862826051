import wretch from 'wretch';
import authMiddleware from './middlewares';

export default function apiAllocation() {
  return wretch()
    .url(process.env.REACT_APP_ALLOCATION_URL)
    .headers({
      appVersion: process.env.REACT_APP_VERSION,
      service: 'driverapp-routes-list'
    })
    .middlewares([authMiddleware], true)
    .errorType('json');
}
