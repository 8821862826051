import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { phoneMachineEvents } from 'view/state-machines/phone-verification.machine';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { verificationCodeMsgs, useStyles } from './constants';

export default function VerificationCodeTemplate({
  isDesktop,
  phoneVerificationMachine
}) {
  const [inputValue, setInputValue] = useState('');
  const { current, send } = phoneVerificationMachine;

  const subtitle = verificationCodeMsgs.subtitle.replace(
    'phone_user',
    current.context.driver.mobileNumber
  );
  const classes = useStyles();

  const valid = current.matches('editing.validation.valid');
  const resendable = current.matches('editing.resend.resendable');

  const handleChange = event => {
    send(phoneMachineEvents.change, {
      code: event.target.value
    });

    setInputValue(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    send(phoneMachineEvents.submit);
  };

  const handleResend = event => {
    event.preventDefault();
    send(phoneMachineEvents.resend);
  };

  return (
    <Box className={classes.contentText}>
      <Box flexGrow={1} alignItems="flex-start">
        <Box width="100%">
          <Box pt={5}>
            <Typography variant="h5">{verificationCodeMsgs.title}</Typography>
          </Box>

          <Box pt={1.5}>
            <Typography variant="subtitle2">{subtitle}</Typography>
          </Box>

          <Box pt={4}>
            <TextField
              id="verification-code-input"
              variant="outlined"
              placeholder={verificationCodeMsgs.code}
              fullWidth
              onChange={handleChange}
              onBlur={handleChange}
              value={inputValue}
              inputProps={{ maxLength: 6 }}
            />
          </Box>

          <Box pt={3.5} textAlign="center">
            <Typography
              variant="body2"
              component="span"
              className={resendable ? classes.link : classes.disableLink}
              onClick={handleResend}
            >
              {verificationCodeMsgs.resendCode}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={isDesktop ? 5.5 : 3.5} pb={2.5}>
        <Button
          variant="contained"
          data-testid="verification-code-button"
          color="primary"
          size="large"
          fullWidth
          disabled={!valid}
          onClick={handleSubmit}
        >
          {verificationCodeMsgs.verify}
        </Button>
      </Box>
    </Box>
  );
}

VerificationCodeTemplate.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  phoneVerificationMachine: PropTypes.shape({
    current: PropTypes.shape({
      matches: PropTypes.func.isRequired,
      context: PropTypes.shape({
        driver: PropTypes.shape({
          mobileNumber: PropTypes.string.isRequired
        })
      })
    }).isRequired,
    send: PropTypes.func.isRequired
  }).isRequired
};
