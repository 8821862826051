import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';
import TEXT from './constants';

const numberFormatter = new Intl.NumberFormat('pt-BR');

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

/**
 * Convert value to currency BRL
 *
 * @param value
 * @returns string containing a currency BRL
 */
export const formatMoney = value => currencyFormatter.format(value);

/**
 * Only use this function for tests!!
 * Convert value to currency BRL and replace space code u00a0 for space.
 * Jest tests does not make the make between a formatted value vs a formatted
 * value in the component due to 'u00a0' difference.
 *
 * @param value
 * @returns string containing a currency BRL
 */
export const formatMoneyForTests = value =>
  currencyFormatter.format(value).replace(/\u00a0/g, ' ');

/**
 * Convert meters in Km
 *
 * @param {int} distance in meters
 * @returns string containing distance in Km
 */
export const parseDistance = distance => {
  const distanceInKm = distance / 1000;
  const roundedDistance = Math.round(distanceInKm * 100) / 100;
  const formatedDistance = numberFormatter.format(roundedDistance);

  return `${formatedDistance} km`;
};

/**
 * Convert meters in Km for a meter length greater than 600 meters.
 * Don't leave any decimal, always round up. E.g.: 4.1km => 5km
 *
 * @param {int} distance in meters
 * @returns formatted string already with unit measure
 */
export const noDecimalFormatDistance = meters => {
  return meters > 600 ? `${Math.ceil(meters / 1000)} km` : `${meters} m`;
};

/**
 * The Offer payload always will return the total of steps. The offer-card always shown the first and last
 * waypoints of route, because of this we need to subtract these waypoints to show extra steps.
 * Back-end always return at least 2 steps.
 *
 * @param {object} offer
 * @param {number} offer.steps
 * @return {number} The number of extra steps
 */
const getExtraStepsFromOffer = offer => offer.steps - 2;

/**
 * Verify if the offer has extra steps
 *
 * @param {object} offer
 * @param {number} offer.steps
 * @return {boolean} Has extra steps
 */
export const hasExtraSteps = offer => getExtraStepsFromOffer(offer) > 0;

/**
 * Parse extra steps
 *
 * @param {object} offer
 * @param {number} offer.steps
 * @return {string} The number of extra steps with correct suffix
 */
export const parseExtraSteps = offer => {
  const extraSteps = getExtraStepsFromOffer(offer);
  const suffix = extraSteps === 1 ? TEXT.WAYPOINT : TEXT.WAYPOINTS;

  return `${extraSteps} ${suffix}`;
};

/**
 * Parse ISO8601 Date in HH:mm (Hours minute) format
 *
 * @param {string} isoDate
 * @return {string} The time in format HH:mm
 */
export const parseISODateToHours = isoDate => {
  const date = new Date(isoDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const additionalZeroInHours = hours < 10 ? '0' : '';
  const additionalZeroInMinutes = minutes < 10 ? '0' : '';

  return `${additionalZeroInHours}${hours}:${additionalZeroInMinutes}${minutes}`;
};

/**
 * Create the style for the Backdrop to render the offer details
 */
export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  firstWaypoint: {
    color: colors.bassis[700],
    background: 'rgba(0, 149, 255, 0.08)'
  },
  otherWaypoint: {
    color: colors.smoke[900],
    background: colors.smoke[50]
  },
  moreOffers: {
    color: colors.bassis[700],
    fontWeight: theme.typography.fontWeightMedium
  },
  acceptButton: {
    background: colors.gradients.bassis,
    color: colors.root[0]
  }
}));

export const getDiffInDaysBetweenDateAndNow = date => {
  const diffInDaysMath = 1000 * 60 * 60 * 24;
  const datetime = new Date(date);
  const today = new Date();

  return Math.round(Math.abs((datetime - today) / diffInDaysMath));
};

export const enumWayPointType = {
  load: 'TRIP_SERVICE_TYPE_LOAD',
  unload: 'TRIP_SERVICE_TYPE_UNLOAD'
};
