import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  CircularProgress,
  Divider,
  Backdrop
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import {
  formatMoney,
  parseISODateToHours,
  parseDistance,
  hasExtraSteps,
  parseExtraSteps,
  useStyles
} from 'view/molecules/offer-card/util';
import TEXT from './constants';
import { RoundListMarker, OfferTag } from './common';
import OfferDetail from './offer-detail';

function OfferCombined({
  offer,
  originDistance,
  onAccept,
  onReject,
  hasReject
}) {
  const hasDistance = originDistance !== null;
  const [isRouteDetailsOpen, setIsRouteDetailsOpen] = useState(false);
  const classes = useStyles();

  const handleStateRouteDetails = () => {
    setIsRouteDetailsOpen(
      currentIsRouteDetailsOpen => !currentIsRouteDetailsOpen
    );
  };

  return (
    <Box>
      <OfferTag message={offer.tagMessage} bgcolor={offer.tagColor} />
      {offer.combined && (
        <Box
          p={2.5}
          pb={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          data-testid="offer-combined"
        >
          <Box height={72} width={290} alignItems="center">
            <Typography variant="h6" fontWeight="bold">
              Coleta para mais tarde na{' '}
              <strong style={{ color: '#2E3545' }}>mesma região!</strong>
            </Typography>
          </Box>
        </Box>
      )}

      <Box alignItems="center">
        <Box textAlign="center" pb={1}>
          <Typography
            style={{ fontWeight: 600 }}
            variant="body1"
            color="textSecondary"
          >
            {TEXT.OFFER_VALUE}
          </Typography>
          <Box>
            <Typography variant="h4" fontWeight="bold">
              <strong>{formatMoney(offer.price)}</strong>
            </Typography>
          </Box>
        </Box>
      </Box>

      {offer.deadlineCollect && offer.startTimeCollect && (
        <Box textAlign="center" p={0.5} data-testid="offer-deadline-collect">
          <Typography
            style={{ fontWeight: 600 }}
            variant="body1"
            color="textSecondary"
          >
            {TEXT.DEADLINE_COLLECT_AT}
          </Typography>
          <Typography variant="h4">
            <Box fontWeight="fontWeightMedium">
              {parseISODateToHours(offer.startTimeCollect)} até{' '}
              {parseISODateToHours(offer.deadlineCollect)}
            </Box>
          </Typography>
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="space-evenly"
        textAlign="center"
        pt={2}
        pb={3}
      >
        <Box>
          <Typography
            style={{ fontWeight: 600 }}
            variant="body1"
            color="textSecondary"
          >
            {TEXT.FIRST_STOP_DISTANCE_AT}
          </Typography>
          <Box>
            {hasDistance ? (
              <Typography variant="body1">{originDistance}</Typography>
            ) : (
              <Box
                textAlign="center"
                color={colors.blue[500]}
                data-testid="offer-distance-loading"
              >
                <CircularProgress size={20} color="inherit" />
              </Box>
            )}
          </Box>
        </Box>

        <Divider orientation="vertical" style={{ height: '54px' }} />

        <Box>
          <Typography
            style={{ fontWeight: 600 }}
            variant="body1"
            color="textSecondary"
          >
            {TEXT.TOTAL}
          </Typography>
          <Typography variant="body1">
            {parseDistance(offer.distance)}
          </Typography>
        </Box>
      </Box>

      <Box flexDirection="column" alignItems="center">
        <Box display="flex" pb={2}>
          <Box
            width="100px"
            pt={0.25}
            pr={3}
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography variant="body1" color="textSecondary">
              {TEXT.START}:
            </Typography>

            <RoundListMarker
              bgcolor={colors.blue[500]}
              txtcolor={colors.root[0]}
              index={offer.waypoints[0].indexDisplay}
            />
          </Box>

          <Box>
            <Typography>{offer.waypoints[0].neighborhood}</Typography>
            <Typography variant="caption" color="textSecondary">
              {offer.waypoints[0].city}
            </Typography>
          </Box>
        </Box>

        {hasExtraSteps(offer) && (
          <Box display="flex" pb={2} pl={7} alignItems="center">
            <Box
              width="20px"
              height="20px"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor={colors.blue[500]}
              color={colors.root[0]}
              onClick={handleStateRouteDetails}
            >
              +
            </Box>
            <Box pl={2.5}>
              <Typography variant="body2">{parseExtraSteps(offer)}</Typography>
            </Box>
            <Backdrop
              className={classes.backdrop}
              open={isRouteDetailsOpen}
              onClick={handleStateRouteDetails}
              data-testid="offer-detail"
            >
              <OfferDetail
                offer={offer}
                onAccept={onAccept}
                onReject={onReject}
                hasReject={hasReject}
              />
            </Backdrop>
          </Box>
        )}

        <Box display="flex" alignItems="center">
          <Box
            width="100px"
            pt={0.25}
            pr={3}
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography variant="body1" color="textSecondary">
              {TEXT.END}:
            </Typography>

            <RoundListMarker
              bgcolor={colors.blue[500]}
              txtcolor={colors.root[0]}
              index={offer.waypoints[offer.waypoints.length - 1].indexDisplay}
            />
          </Box>

          <Box>
            <Typography>
              {offer.waypoints[offer.waypoints.length - 1].neighborhood}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {offer.waypoints[offer.waypoints.length - 1].city}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

OfferCombined.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    deadlineCollect: PropTypes.string,
    startTimeCollect: PropTypes.string,
    combined: PropTypes.bool,
    shipperName: PropTypes.string,
    tagMessage: PropTypes.string,
    tagColor: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,

  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  hasReject: PropTypes.bool,
  originDistance: PropTypes.string.isRequired
};

OfferCombined.defaultProps = {
  onReject: () => null,
  hasReject: true
};

export default React.memo(OfferCombined);
