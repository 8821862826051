import { getCognitoIdToken, getCredentials } from '../services/login';

const authMiddleware = next => async (url, options) => {
  const cognitoToken = await getCognitoIdToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: cognitoToken || getCredentials()
  };

  const optionsWithAuthToken = {
    ...options,
    headers: {
      appVersion: process.env.REACT_APP_VERSION,
      service: 'driverapp-routes-list',
      ...headers
    }
  };

  return next(url, optionsWithAuthToken);
};

export default authMiddleware;
