import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const Header = ({ title, subtitle }) => {
  return (
    <Box py={4}>
      <Box pt={1}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      {subtitle && (
        <Box pt={1}>
          {typeof subtitle === 'string' ? (
            <Typography variant="subtitle2">{subtitle}</Typography>
          ) : (
            subtitle
          )}
        </Box>
      )}
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node
};

Header.defaultProps = {
  subtitle: null
};

export default Header;
