import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Chip } from '@material-ui/core';
import { colors } from '@loggi/mar';

import TEXT from './constants';

export function RoundListMarker({ bgcolor, txtcolor, index }) {
  return (
    <Box
      width="25px"
      height="25px"
      borderRadius="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={bgcolor}
      color={txtcolor}
      ml={1}
      border={1}
      borderColor={colors.blue[500]}
    >
      {index}
    </Box>
  );
}

export function OfferTag({ message, mt, mb, bgcolor }) {
  if (!message) {
    return <></>;
  }

  return (
    <Box mt={mt} mb={mb}>
      <Chip
        data-testid="offer-tag"
        label={message}
        size="small"
        style={{
          borderRadius: '0.5rem',
          color: colors.light[500],
          backgroundColor: bgcolor || colors.light[50]
        }}
      />
    </Box>
  );
}

export function AcceptOfferButton({
  handleAccept,
  size,
  mx,
  className,
  message
}) {
  return (
    <Button
      variant="contained"
      color={className ? undefined : 'primary'}
      className={className}
      onClick={handleAccept}
      size={size}
      data-testid="accept-offer-button"
    >
      <Box mx={mx}>{message}</Box>
    </Button>
  );
}

export function RejectOfferButton({ handleReject }) {
  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={handleReject}
      size="large"
      data-testid="reject-offer-button"
    >
      <Box mx={1}>{TEXT.BUTTON_REJECT_OFFER}</Box>
    </Button>
  );
}

RoundListMarker.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  txtcolor: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired
};

AcceptOfferButton.propTypes = {
  handleAccept: PropTypes.func.isRequired,
  size: PropTypes.string,
  mx: PropTypes.number,
  className: PropTypes.string,
  message: PropTypes.string
};

AcceptOfferButton.defaultProps = {
  size: 'large',
  mx: 1.5,
  className: undefined,
  message: TEXT.BUTTON_ACCEPT_OFFER
};

RejectOfferButton.propTypes = {
  handleReject: PropTypes.func.isRequired
};

OfferTag.propTypes = {
  message: PropTypes.string.isRequired,
  mt: PropTypes.number,
  mb: PropTypes.number,
  bgcolor: PropTypes.string
};

OfferTag.defaultProps = {
  mt: 0.5,
  mb: 0.5,
  bgcolor: colors.light[50]
};

export default {
  AcceptOfferButton,
  RoundListMarker,
  RejectOfferButton,
  OfferTag
};
