import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, ListItem, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  noDecimalFormatDistance,
  hasExtraSteps,
  formatMoney,
  parseExtraSteps,
  enumWayPointType,
  parseISODateToHours
} from 'view/molecules/offer-card/util';
import CONSTANTS from 'view/molecules/offer-card/constants';
import { ReactComponent as PointIcon } from 'view/images/point.svg';
import { ReactComponent as OriginIcon } from 'view/images/origin.svg';
import { ReactComponent as VectorIcon } from 'view/images/vector.svg';
import { ReactComponent as PointIntermediateIcon } from 'view/images/point_intermediate.svg';
import TEXT from './constants';
import { OfferTag } from './common';

function OfferTrip({ offer, originDistance }) {
  const [isRouteDetailsOpen, setIsRouteDetailsOpen] = useState(false);

  const handleStateRouteDetails = () => {
    setIsRouteDetailsOpen(
      currentIsRouteDetailsOpen => !currentIsRouteDetailsOpen
    );
  };

  const getWaypointInformation = waypoint => {
    let text = TEXT.WAYPOINT_LOAD;
    if (waypoint.typeService === enumWayPointType.unload)
      text = TEXT.WAYPOINT_UNLOAD;

    text += ` até ${parseISODateToHours(waypoint.deadlineDisplay)}`;
    return text;
  };

  const getAmountWaypointByType = type => {
    const waypointsByType = offer.waypoints.filter(
      waypoint => waypoint.typeService === type
    );

    let text = TEXT.WAYPOINT_LOAD_PLACE;
    if (type === enumWayPointType.unload) text = TEXT.WAYPOINT_UNLOAD_PLACE;

    if (waypointsByType.length > 1)
      text = text.replace('ar', 'ares').replace('al', 'ais');

    return `${waypointsByType.length} ${text}`;
  };

  const getAmountWaypointUnload = () => {
    return getAmountWaypointByType(enumWayPointType.unload);
  };

  const getAmountWaypointLoad = () => {
    return getAmountWaypointByType(enumWayPointType.load);
  };

  return (
    <Box>
      <Box ml={2} paddingBottom={1.875} textAlign="left">
        <Typography variant="h4">
          Retire em <strong>{getAmountWaypointLoad()}</strong>
        </Typography>
        <Typography variant="h4">
          entregue tudo em <strong>{getAmountWaypointUnload()}</strong>
        </Typography>
      </Box>
      <Box ml={2} paddingBottom={3} textAlign="left">
        <OfferTag message={offer.tagMessage} bgcolor={offer.tagColor} mt={2} />
        <Typography variant="h2">
          Até <strong> {formatMoney(offer.price)}</strong>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {originDistance} de você • {noDecimalFormatDistance(offer.distance)}{' '}
          {CONSTANTS.TOTALS}
        </Typography>
      </Box>

      <Box>
        <Box display="flex" pb={2} alignItems="center">
          <Box
            pr={2}
            pl={1.5}
            textAlign="right"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <OriginIcon height={22} width={22} />
          </Box>

          <Box>
            <Typography variant="body1">
              <strong>{offer.waypoints[0].neighborhood}</strong>
            </Typography>
            <Typography color="textSecondary">
              {getWaypointInformation(offer.waypoints[0])}
            </Typography>
          </Box>
        </Box>
        {hasExtraSteps(offer) && (
          <div>
            <Box ml={1.5}>
              <VectorIcon height={22} width={22} />
            </Box>
            <ListItem
              component="nav"
              aria-labelledby="nested-list-subheader"
              button
              key={offer.waypoints[0].indexDisplay}
              onClick={handleStateRouteDetails}
              data-testid="offer-detail"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <PointIntermediateIcon height={22} width={15} />
                <Box ml={3}>
                  <Typography variant="body1">
                    {` + ${parseExtraSteps(offer)}`}
                  </Typography>
                  <Typography style={{ color: '#006AFF' }} variant="body1">
                    Mostrar paradas
                  </Typography>
                </Box>
              </Box>
              {isRouteDetailsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              key={offer.waypoints.Id}
              in={isRouteDetailsOpen}
              unmountOnExit
            >
              <Box maxHeight={window.innerHeight * 0.2} overflow="auto">
                {offer.waypoints.slice(1, -1).map(waypoint => (
                  <>
                    <Box ml={2}>
                      <VectorIcon height={22} width={15} />
                    </Box>
                    <Box
                      mb={1}
                      ml={2}
                      display="flex"
                      alignItems="center"
                      key={waypoint.indexDisplay}
                    >
                      <PointIntermediateIcon height={22} width={15} />
                      <Box ml={1.5}>
                        <Typography color="textPrimary">
                          {waypoint.neighborhood}
                        </Typography>
                        <Typography color="textSecondary">
                          {getWaypointInformation(waypoint)}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ))}
              </Box>
            </Collapse>
          </div>
        )}
        <Box ml={1.5}>
          <VectorIcon height={22} width={22} />
        </Box>
        <Box display="flex" pt={2} alignItems="center">
          <Box
            pr={2}
            pl={1.5}
            textAlign="right"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <PointIcon height={22} width={22} />
          </Box>
          <Box>
            <Typography variant="body1">
              <strong>
                {offer.waypoints[offer.waypoints.length - 1].neighborhood}
              </strong>
            </Typography>
            <Typography color="textSecondary">
              {getWaypointInformation(
                offer.waypoints[offer.waypoints.length - 1]
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

OfferTrip.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    countLoad: PropTypes.number.isRequired,
    CountUnload: PropTypes.number.isRequired,
    deadlineCollect: PropTypes.string,
    shipperName: PropTypes.string,
    tagMessage: PropTypes.string,
    tagColor: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        type: PropTypes.number.isRequired,
        deadline: PropTypes.string,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,

  originDistance: PropTypes.string.isRequired
};

export default React.memo(OfferTrip);
