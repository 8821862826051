import api from 'infra/api';
import geolocation from './geolocation';

export default async function getOffer(offerId) {
  const pos = await geolocation();
  const posParams = `?latitude=${pos.latitude}&longitude=${pos.longitude}`;
  const url = `/offer/${offerId}${posParams}`;
  return api()
    .url(url)
    .get()
    .json();
}
