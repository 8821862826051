import { getDriverAppOfferBridgeCognitoToken } from 'infra/services/app-bridge';
import { getAuthorizationTokens } from '@loggi/authentication-lib';
import { getParameter } from './url-parser';

/**
 * Get ApiKey from auth query string
 *
 * The ApiKey is composite by driver's email and api_key and is provided by DriverApp as an URL parameter with below pattern:
 *
 *    ApiKey driver_email:driver_api_key
 *
 * Example of a provided URL:
 *
 *    https://www.example.loggi.com?auth=ApiKey email@loggi.com:fd188cb85441d7bf136d195587094a6ff0376add
 *
 * @returns {string} ApiKey
 */
export const getCredentials = () =>
  window?.driverAppBridge?.getLWApiKey?.() || getParameter('auth');

export const getCognitoIdToken = async () => {
  const { idToken } = await getAuthorizationTokens();

  if (idToken) {
    return `Bearer ${idToken}`;
  }

  // necessary to give retrocompatibility with the offer push webview in driver-app
  // it uses it's own webview "driverAppOfferBridge" instead of the "driverAppBridge"
  if (getDriverAppOfferBridgeCognitoToken()) {
    return `Bearer ${getDriverAppOfferBridgeCognitoToken()}`;
  }

  return null;
};

export const isAuthenticatedWithApiKey = () => !!getCredentials();

export const isAuthenticatedWithCognito = user => {
  try {
    return !Number.isNaN(JSON.parse(user['custom:access']).loggi_user_id);
  } catch {
    return false;
  }
};

export const isSupport = user => user?.['cognito:groups'].includes('support');

export const isAuthenticatedWithCognitoOrApiKey = user => {
  return isAuthenticatedWithCognito(user) || isAuthenticatedWithApiKey();
};
