import React from 'react';
import { CheckCircle, Error } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import { assign, sendParent } from 'xstate';

export const formActions = {
  edit: 'EDIT',
  change: 'CHANGE',
  submit: 'SUBMIT',
  retry: 'RETRY'
};

export const formState = {
  idle: 'idle',
  loading: 'loading',
  error: 'error',
  editing: 'editing',
  invalid: 'invalid',
  valid: 'valid',
  submitting: 'submitting',
  success: 'success',
  failure: 'failure',
  documentValid: 'document_valid',
  documentInvalid: 'document_invalid'
};

export const emptyField = (initialValue = '') => ({
  touched: false,
  value: initialValue,
  errors: []
});

export const mergeValidationErrors = ({ fields }, event) => {
  const errorFields = event.data.errors.filter(item => 'field' in item);
  const commonErrors = event.data.errors.filter(item => !('field' in item));

  return {
    fields: errorFields.reduce((newFields, item) => {
      const fieldName = item.field;

      return {
        ...newFields,
        [fieldName]: {
          ...fields[fieldName],
          errors: [item.message]
        }
      };
    }, fields),
    errors: commonErrors
  };
};

export const sendSuccessNotification = message =>
  sendParent(() => {
    return {
      type: 'NOTIFICATION.SET',
      color: colors.green[500],
      startAdornment: <CheckCircle fontSize="large" />,
      message
    };
  });

export const sendErrorNotification = (
  customMessage,
  { getErrorFromEvent } = { getErrorFromEvent: false }
) =>
  sendParent((_, { data }) => {
    return {
      type: 'NOTIFICATION.SET',
      color: colors.red[500],
      startAdornment: <Error fontSize="large" />,
      message: getErrorFromEvent ? data : customMessage
    };
  });

const infoValid = ({ fields }) =>
  Object.values(fields).every(({ errors }) => !errors.length);

export const formValid = context => infoValid(context);

export const formInvalid = context => !formValid(context);

export const changeField = assign({
  fields: (context, event) => {
    const { name, value, touched } = event;
    const currentField = context.fields[name];

    return {
      ...context.fields,
      [name]: {
        ...currentField,
        value: value !== undefined ? value : currentField.value,
        touched: touched !== undefined ? touched : currentField.touched
      }
    };
  }
});

const cleanAllErrorsFromFields = fields =>
  Object.keys(fields).reduce(
    (newFields, fieldName) => ({
      ...newFields,
      [fieldName]: {
        ...fields[fieldName],
        errors: []
      }
    }),
    fields
  );

export const fieldsValuesFromContext = context =>
  Object.entries(context.fields).reduce(
    (fields, [fieldName, { value }]) => ({
      ...fields,
      [fieldName]: value
    }),
    {}
  );

export const fieldsFromFieldValues = fieldValues =>
  Object.entries(fieldValues).reduce(
    (fields, [fieldName, value]) => ({
      ...fields,
      [fieldName]: emptyField(value)
    }),
    {}
  );

const mergeFormatResultWithFields = (formatResult, fields) => {
  return Object.entries(fields).reduce(
    (newFields, [fieldName, fieldValue]) => ({
      ...newFields,
      [fieldName]: {
        ...fieldValue,
        value: formatResult[fieldName]
      }
    }),
    {}
  );
};

const mergeValidationErrorsWithFields = (validationResult, fields) => {
  const errorsByField = validationResult.inner.reduce(
    (acc, { path, errors }) => ({
      ...acc,
      [path]: errors
    }),
    {}
  );

  return Object.entries(fields).reduce(
    (newFields, [fieldName, fieldValue]) => ({
      ...newFields,
      [fieldName]: {
        ...fieldValue,
        value: validationResult.value[fieldName],
        errors: errorsByField[fieldName] || []
      }
    }),
    {}
  );
};

export const validateFieldsAndMergeErrors = (schema, context) => {
  const fieldsValues = fieldsValuesFromContext(context);

  try {
    const formatResult = schema.validateSync(fieldsValues, {
      abortEarly: false
    });
    return mergeFormatResultWithFields(
      formatResult,
      cleanAllErrorsFromFields(context.fields)
    );
  } catch (validationErrors) {
    return mergeValidationErrorsWithFields(
      validationErrors,
      cleanAllErrorsFromFields(context.fields)
    );
  }
};

export const initialContextFromFieldsValues = (initialContext, schema) => {
  const fields = validateFieldsAndMergeErrors(schema, initialContext);
  return {
    ...initialContext,
    fields
  };
};

export const validateFields = schema =>
  assign({
    fields: context => validateFieldsAndMergeErrors(schema, context)
  });
