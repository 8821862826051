import React from 'react';

import { colors } from '@loggi/mar';
import { Box } from '@material-ui/core';

const Puller = () => (
  <Box width="100%" display="flex" justifyContent="center" mt={1}>
    <Box
      width="2.5rem"
      height="0.5rem"
      bgcolor={colors.smoke[100]}
      borderRadius="2rem"
    />
  </Box>
);

export default React.memo(Puller);
