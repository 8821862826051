import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { colors } from '@loggi/mar';

export default function LoadingTemplate() {
  return (
    <Box
      width="100%"
      minHeight="100%"
      display="flex"
      flexDirection="column"
      bgcolor={colors.root[0]}
      py={5.5}
      data-testid="loading"
      color={colors.blue[500]}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
      >
        <CircularProgress size={40} color="inherit" />
      </Box>
    </Box>
  );
}
