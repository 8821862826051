import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { List, Typography, Backdrop } from '@material-ui/core';
import { colors } from '@loggi/mar';
import getFilters from 'infra/services/get-driver-filters';
import getAvailableOffers from 'infra/services/get-driver-offers';
import { parseOfferProtoToOfferCard } from 'infra/services/parsers';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import FilterItem from 'view/molecules/offer-list-item';
import OfferCard from 'view/molecules/offer-card/v2/index';
import OfferCardCapacityReserve from 'view/molecules/offer-card/v2/offer-card-capacity-reserve';
import Loading from 'view/molecules/loading';
import BackHeader from 'view/molecules/back-header';
import OfferDetails from 'view/pages/offer-details';
import { useStyles } from 'view/molecules/offer-card/util';
import ErrorAlert from 'view/molecules/error-alert';
import EmptyOfferComponent from 'view/molecules/empty-list';
import { acceptOfferService } from 'infra/services/accept-offer';
import CapacityReserveDetails from 'view/pages/offer-details/capacity-reserve-details';
import CapacityReserveNotificationDrawer from 'view/templates/showcase/showcase-message/capacity-reserve-notification-drawer';
import OfferCardOpportunistic from 'view/molecules/offer-card/v2/offer-card-opportunistic';
import logOfferNotificationService from 'infra/services/log-offer-notification';
import Puller from '../../../molecules/puller';

/**
 * Filter shown in the select representing all routes
 */
const NO_FILTER = { label: 'Todas as bases', value: '' };

/**
 * The default value for the minimum number of availaber offers
 * to also render the filters.
 */
const MINIMUN_OFFERS_FOR_FILTER_DEFAULT = 4;

function shouldRenderFilter(filterPayload, offersLength, stickFilter) {
  // We initially render the filter if there are filter items available
  // and a minimum number of offers in the list.
  // If the user interacted if the filter once, we stick it.
  if (!filterPayload) {
    return false;
  }

  const minOffersToShowFilter =
    filterPayload.minOffersToShowFilter == null
      ? MINIMUN_OFFERS_FOR_FILTER_DEFAULT
      : filterPayload.minOffersToShowFilter;

  const hasFilters = Boolean(
    filterPayload && filterPayload.items && filterPayload.items.length > 0
  );

  const hasMinimumOffers = offersLength >= minOffersToShowFilter;

  return Boolean(hasFilters) && Boolean(hasMinimumOffers || stickFilter.value);
}

const OffersPage = ({ offersService, filterService }) => {
  const [offerCards, setOfferCards] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [filterPayload, setFilterPayload] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(NO_FILTER);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [offerLoading, setOfferLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState(-1);
  const classes = useStyles();

  const lmcsEnabledToCallAllocation = useFeatureSwitch(
    featureSwitches.callAcceptOfferDirectlyRdShowcase
  );
  const ignoreProxy = featureSwitchEnabledForDriverLMC(
    lmcsEnabledToCallAllocation
  );

  const updateOffers = () => {
    setOfferLoading(true);
    const { type: filterType } = { ...filterPayload };
    const filterValue = selectedFilter.value;
    offersService(filterType, filterValue)
      .then(response => {
        const offers = response.offers?.offerList
          ? response.offers.offerList.map(offer =>
              parseOfferProtoToOfferCard(offer)
            )
          : [];

        setOfferCards(offers);
        setDisabled(response.disabled);
        // Closes the filter drawer when loading new offers.
        setDrawerOpen(false);
      })
      .catch(err => setError(err.message))
      .finally(() => setOfferLoading(false));
  };

  // Run on start up, and everytime selectedFilter changes.
  useEffect(updateOffers, [offersService, selectedFilter, filterPayload]);

  useEffect(() => {
    setFilterLoading(true);
    filterService()
      .then(filters => {
        if (filters) {
          setFilterPayload(filters);
        } else {
          setFilterPayload({ filter: {} });
        }
      })
      .catch(err => setError(err.message))
      .finally(() => setFilterLoading(false));
  }, [filterService]);

  const handleCloseErrorAlert = () => setError('');
  // eslint-disable-next-line no-unused-vars
  const handleAcceptOffer = index => async offer => {
    setOfferLoading(true);
    acceptOfferService(offer, index, ignoreProxy)
      .catch(err => {
        setError(err.message);
      })
      .finally(() => setOfferLoading(false));
  };

  const handleOfferSelect = (offer, index) => {
    setSelectedOffer(offer);
    setSelectedOfferIndex(index);
  };

  const handleOpenOfferDetails = () => {
    if (selectedOffer && selectedOfferIndex > -1) {
      return (
        <Backdrop className={classes.backdrop} open={selectedOffer}>
          {selectedOffer.capacityReserve ? (
            <CapacityReserveDetails
              offer={selectedOffer}
              onAccept={handleAcceptOffer(selectedOfferIndex)}
              onBack={() => handleOfferSelect(null, -1)}
            />
          ) : (
            <OfferDetails
              offer={selectedOffer}
              onAccept={handleAcceptOffer(selectedOfferIndex)}
              onBack={() => handleOfferSelect(null, -1)}
            />
          )}
        </Backdrop>
      );
    }
    return null;
  };

  const filterList = () => {
    // There's a bug in material UI v4, that when using a SwipeableDrawer,
    // when the anchor is bottom or top, we can't scroll.
    // Using height 100% and overflow auto in this nested div inside the drawer
    // fixes the issue.
    // https://github.com/mui/material-ui/issues/16942#issuecomment-1077980288
    return (
      <Box px="24px" mt="52px" height="100%" overflow="auto">
        <Typography variant="subtitle1">
          <Box fontWeight={600}>Filtrar por base</Box>
        </Typography>

        {filterPayload.title ? (
          <Typography variant="body2" data-testid="filterTitle">
            <Box mt="24px" fontWeight={700} color={colors.smoke[500]}>
              {filterPayload.title.toUpperCase()}
            </Box>
          </Typography>
        ) : null}

        <List overflow="auto">
          <FilterItem
            item={NO_FILTER}
            divider={filterPayload.items.length === 0}
            selected={selectedFilter.value === NO_FILTER.value}
            onSelect={() => setSelectedFilter(NO_FILTER)}
          />
          {filterPayload.items.map((item, index) => (
            <FilterItem
              item={item}
              divider={filterPayload.items.length === index + 1}
              selected={selectedFilter.value === item.value}
              onSelect={() => setSelectedFilter(item)}
            />
          ))}
        </List>
      </Box>
    );
  };

  const filterButton = () => {
    if (!shouldRenderFilter(filterPayload, offerCards.length, selectedFilter)) {
      return null;
    }

    return (
      <Box mb={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel px="20px">{selectedFilter.label}</InputLabel>
          <Select
            data-testid="filterBox"
            style={{ borderRadius: 30, fontSize: '14px' }}
            label="Todas as bases"
            disabled
            value=""
            onClick={() => setDrawerOpen(true)}
          />
        </FormControl>

        <SwipeableDrawer
          anchor="bottom"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: '1rem',
              borderTopRightRadius: '1rem',
              maxHeight: window.innerHeight - 40
            }
          }}
        >
          <Puller />
          {filterList()}
        </SwipeableDrawer>
      </Box>
    );
  };

  const offerCardsCapacityReserve = offerCards.filter(
    offer => offer.capacityReserve
  );
  const offerCardsOpportunistic = offerCards.filter(
    offer => offer.opportunistic
  );
  const offerCardsDefaults = offerCards.filter(
    offer => !offer.capacityReserve && !offer.opportunistic
  );

  useEffect(() => {
    logOfferNotificationService.logOffersPresented(offerCards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerCards.length]);

  const renderOfferList = () => {
    let component = (
      <>
        {offerCardsOpportunistic.length > 0 && (
          <>
            <Box>
              <Typography variant="body1">
                <b>Em destaque</b>
              </Typography>
            </Box>
            {offerCardsOpportunistic.map((offer, index) => (
              <Box
                data-testid={`opportunistic-offer-${offer.id}`}
                key={`opportunistic-offer-${offer.id}`}
                onClick={() => handleOfferSelect(offer, index)}
                tabIndex="0"
                mb={2}
              >
                <OfferCardOpportunistic offer={offer} />
              </Box>
            ))}
          </>
        )}
        {offerCardsCapacityReserve?.length > 0 && (
          <>
            <Box>
              <Typography variant="body1">
                <b>Para amanhã</b>
              </Typography>
            </Box>
            {offerCardsCapacityReserve.map((offer, index) => (
              <Box
                data-testid={`capacity-offer-${offer.id}`}
                key={`capacity-offer-${offer.id}`}
                onClick={() => handleOfferSelect(offer, index)}
                onKeyDown={() => ({})}
                role="button"
                tabIndex="0"
                mb={2}
              >
                <OfferCardCapacityReserve offer={offer} />
              </Box>
            ))}
          </>
        )}
        {offerCardsDefaults?.length > 0 && (
          <>
            <Box>
              <Typography variant="body1">
                <b>Para hoje</b>
              </Typography>
            </Box>
            {offerCardsDefaults.map((offer, index) => (
              <Box
                key={`offer-${offer.id}`}
                onClick={() => handleOfferSelect(offer, index)}
                onKeyDown={() => ({})}
                role="button"
                tabIndex="0"
              >
                <OfferCard
                  offer={offer}
                  onAccept={handleAcceptOffer(selectedOfferIndex)}
                />
                {index < offerCards.length - 1 ? <Divider /> : null}
              </Box>
            ))}
          </>
        )}
      </>
    );

    if (!offerCards.length || disabled) {
      component = <EmptyOfferComponent disabled={disabled} />;
    }

    return (
      <Box height="100%" display="flex" flexDirection="column">
        {filterButton()}
        {component}
        {handleOpenOfferDetails()}
      </Box>
    );
  };

  return (
    <>
      <Box
        px="24px"
        py="24px"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <BackHeader />

        <Typography variant="h6" style={{ marginBottom: '33px' }}>
          <Box fontWeight={600}>Todas as ofertas</Box>
        </Typography>

        {offerLoading || filterLoading ? (
          <Loading />
        ) : (
          <>
            {renderOfferList()}

            {error && (
              <ErrorAlert error={error} onClose={handleCloseErrorAlert} />
            )}
          </>
        )}
      </Box>
      <CapacityReserveNotificationDrawer
        hasCapacityReserve={offerCardsCapacityReserve.length > 0}
      />
    </>
  );
};

OffersPage.propTypes = {
  offersService: PropTypes.func,
  filterService: PropTypes.func
};

OffersPage.defaultProps = {
  offersService: getAvailableOffers,
  filterService: getFilters
};

export default OffersPage;
