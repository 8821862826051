import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMachine } from '@xstate/react';
import {
  useAmplifyAuth,
  getAuthorizationTokens
} from '@loggi/authentication-lib';
import Alert from 'view/templates/alert';
import { pages } from 'routes';
import phoneVerificationMachine, {
  phoneMachineStates
} from 'view/state-machines/phone-verification.machine';
import DeviceValidationError from 'view/pages/device-validation-error';
import VerificationCodeTemplate from 'view/templates/verification-code';
import LoadingTemplate from 'view/templates/loading';
import SignupLayoutTemplate from 'view/templates/signup-layout';

export default function SmsVerificationCodePage({
  onSuccessLoginRedirectPath,
  onGoBack
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const history = useHistory();

  const isLogin = history.location.state?.from === pages.login;
  const onBack = () => history.push(onGoBack);

  const [current, send] = useMachine(phoneVerificationMachine);

  const {
    state: { authenticatedUser },
    fetchCurrentUserFromCognito
  } = useAmplifyAuth();

  useEffect(() => {
    send({
      type: 'SETLOGIN',
      data: isLogin
    });
    getAuthorizationTokens().then(({ idToken }) => {
      send({
        type: 'SETCOGNITO',
        data: {
          cognito_token: idToken,
          cognito_email: authenticatedUser?.email
        }
      });
    });
  }, [send, authenticatedUser, isLogin]);

  useEffect(() => {
    if (current.matches(phoneMachineStates.finished)) {
      fetchCurrentUserFromCognito().then(() => {
        if (isLogin) {
          window.location.href = onSuccessLoginRedirectPath;
        }

        history.push(onSuccessLoginRedirectPath, {
          from: pages.smsVerificationCode
        });
      });
    }
  }, [
    current,
    fetchCurrentUserFromCognito,
    history,
    isLogin,
    onSuccessLoginRedirectPath
  ]);

  const defaultPage = () => (
    <VerificationCodeTemplate
      isDesktop={isDesktop}
      phoneVerificationMachine={{ current, send }}
    />
  );

  const component =
    {
      [phoneMachineStates.loading]: () => <LoadingTemplate />
    }[current.value] || defaultPage;

  const {
    notification,
    errorDevice,
    driver: { email }
  } = current.context;
  const hasNotification = !!notification;

  if (errorDevice) {
    return <DeviceValidationError errorDevice={errorDevice} email={email} />;
  }

  const handleCloseNotification = () => send('CLEANNOTIFICATION');

  return (
    <>
      {hasNotification && (
        <Alert
          message={notification.message}
          startAdornment={notification.startAdornment}
          color={notification.color}
          onClose={handleCloseNotification}
          open
        />
      )}
      <SignupLayoutTemplate page={component()} onBack={onBack} />
    </>
  );
}

SmsVerificationCodePage.propTypes = {
  onSuccessLoginRedirectPath: PropTypes.string,
  onGoBack: PropTypes.string.isRequired
};

SmsVerificationCodePage.defaultProps = {
  onSuccessLoginRedirectPath: null
};
