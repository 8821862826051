import { makeStyles } from '@material-ui/core';
import driverImg from 'view/assets/driver.jpg';

export const onboardingPageTexts = {
  title: 'Fazer entregas é nosso negócio.',
  subtitle: 'Locais e nacionais, para conectar o Brasil.',
  titleCognito: 'Conta Google não está associada',
  notFoundAccount: ', não encontramos uma conta Loggi associada ao email:',
  notFoundAccountAction: ', mas você pode associar seu cadastro existente.',
  createAccount: 'Cadastrar uma nova conta',
  loginAction: 'Associar com conta existente',
  wrongEmail: 'Email errado?',
  logoutAction: 'Entrar com outra conta',
  help: 'Ficou com dúvida? Tire todas na',
  helpAction: 'Central de ajuda',
  helpLink: 'https://ajuda.loggi.com/hc/pt-br/sections/360010985111-Acesso'
};

export const useStyles = makeStyles(theme => ({
  headerDesktop: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundPositionX: 'center'
  },
  headerMobile: {
    height: '325px',
    width: '100%',
    backgroundPositionX: '-30px'
  },
  header: {
    backgroundImage: `url(${driverImg})`,
    backgroundSize: 'cover',
    width: '100%'
  },
  contentGridDesktop: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentGridMobile: {
    height: 'calc(100% - 325px)'
  },
  contentTextDesktop: {
    width: '50%'
  },
  contentTextMobile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));
