export const PERMISSION_DENIED_MESSAGE =
  'Você não tem permissão para acessar este serviço, verifique com o administrador';
export const DEFAULT_MESSAGE_500 =
  'Falha no servidor, tente novamente mais tarde';
export const NETWORK_ERROR_MESSAGE = 'Não conseguimos acessar o servidor';
export const NOT_FOUND_MESSAGE = 'Endereço não encontrado';
export const DEFAULT_MESSAGE_400 = 'Requisição inválida';

export const RESPONSE_MESSAGES_BY_CODE = {
  400: DEFAULT_MESSAGE_400,
  403: PERMISSION_DENIED_MESSAGE,
  404: NOT_FOUND_MESSAGE,
  500: DEFAULT_MESSAGE_500
};

export function translateStatusCode(status, message = '') {
  if (RESPONSE_MESSAGES_BY_CODE[status]) {
    const messageError = RESPONSE_MESSAGES_BY_CODE[status];

    return {
      errors: [{ message: messageError }],
      code: status
    };
  }

  if (message) {
    const json = JSON.parse(message);
    const { errors } = json || {};

    if (errors === undefined) {
      const REMOVE_QUOTES = new RegExp(/('|")/g);
      const messageError = message.replace(REMOVE_QUOTES, '');
      return { errors: [{ message: messageError }], code: status };
    }

    return { errors, code: status };
  }

  return { errors: [], code: 0 };
}
