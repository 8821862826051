import { createContext, useContext } from 'react';

const FormContext = createContext({});

export const useForm = () => {
  return useContext(FormContext);
};

export const { Provider: FormProvider, Consumer: InputConsumer } = FormContext;

export default FormContext;
