import { colors } from '@loggi/mar';
import { smoke } from '@loggi/mar/src/principles/colors';
import { pxToRem } from '@loggi/mar/src/utils';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ReactComponent as OpportunisticIcon } from 'view/images/opportunistic_ico.svg';
import getDistance from '../../../infra/services/get-distance';
import { AcceptOfferButton } from '../../molecules/offer-card/common';
import {
  formatMoney,
  noDecimalFormatDistance,
  parseISODateToHours,
  useStyles
} from '../../molecules/offer-card/util';
import './index.css';
import { ReactComponent as PinIcon } from './pin-round.svg';

const OfferDetails = ({ offer, onAccept, onBack, onMoreOffers }) => {
  const classes = useStyles();
  const [distance, setDistance] = useState('');
  const [deadline, setDeadline] = useState('');

  useEffect(() => {
    getDistance(offer.waypoints[0].coordinates)
      .then(dist => setDistance(noDecimalFormatDistance(dist)))
      .catch(() => setDistance('-'));

    if (offer.deadlineCollect) {
      setDeadline(parseISODateToHours(offer.deadlineCollect));
    }
  }, [offer]);

  const titleComponent = () => {
    if (offer.opportunistic) {
      return (
        <>
          No seu caminho,{' '}
          <Box fontWeight="fontWeightBold" data-testid="originDistance">
            {distance} você!
          </Box>
        </>
      );
    }
    return (
      <>
        Oferta a <strong data-testid="originDistance">{distance}</strong> de
        você!
      </>
    );
  };

  const waypointComponent = (waypoint, first) => {
    const className = first ? classes.firstWaypoint : classes.otherWaypoint;

    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        mt={pxToRem(32)}
      >
        <Box
          borderRadius={pxToRem(4)}
          className={className}
          padding="20px"
          maxWidth={pxToRem(40)}
          maxHeight={pxToRem(40)}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="button">
            <Box fontWeight="fontWeightBoldRegular" fontSize="1rem">
              {waypoint.indexDisplay}
            </Box>
          </Typography>
        </Box>
        <Box ml="16px" display="flex" flexDirection="column">
          <Typography variant="caption">
            <Box fontWeight="fontWeightRegular">{waypoint.city}</Box>
          </Typography>
          <Typography variant="subtitle1">
            <Box fontWeight="fontWeightMedium">{waypoint.neighborhood}</Box>
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      maxHeight="100%"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      bgcolor={colors.root[0]}
      color={colors.smoke[900]}
      data-testid="offer-details-accessed"
    >
      {/* TOP ARROW */}
      <Box
        mt="31px"
        mb="23px"
        display="flex"
        flexDirection="row"
        width="100%"
        px="24px"
        justifyContent="flex-start"
      >
        <Box onClick={onBack}>
          <ArrowBackIcon data-testid="arrow" alt="Go back button" />
        </Box>
      </Box>

      <Box flexGrow={2} overflow="auto" className="list" px="24px">
        {/* TOP */}
        <Box width="100%" mt="20px">
          {offer.opportunistic ? (
            <Box height={22} width={22}>
              <OpportunisticIcon
                data-testid="opportunistic-ico"
                alt="Opportunistic icon"
              />
            </Box>
          ) : (
            <PinIcon
              data-testid="position-ico"
              alt="position icon"
              height={22}
              width={22}
            />
          )}
          <Box mt={pxToRem(8)}>
            {deadline && !offer.opportunistic && (
              <Typography color="textSecondary" variant="body2">
                Ir até {deadline}
              </Typography>
            )}
            <Typography variant="h5" data-testid="offer-header">
              {titleComponent()}
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="body2">
              {offer.waypoints.length} paradas •{' '}
              {`${noDecimalFormatDistance(offer.distance)} totais`}
              {offer.opportunistic && deadline && ` • ir até às ${deadline}`}
            </Typography>
          </Box>
        </Box>

        {/* MID LIST */}
        <Box mt="24px">
          <Typography>Paradas</Typography>
          {waypointComponent(offer.waypoints[0], true)}
          {offer.waypoints.slice(1).map(w => waypointComponent(w, false))}
        </Box>
      </Box>
      {/* BOTTOM */}
      <Box
        py="24px"
        px="24px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingBottom="20px"
        borderTop={`1px solid ${colors.smoke[100]}`}
      >
        <Box
          display="flex"
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography color="textSecondary" variant="body">
                <Box fontWeight="fontWeightBold" color={smoke[900]}>
                  Até
                </Box>
              </Typography>
              <Typography color="textSecondary" variant="h6">
                <Box pl="5px" fontWeight="fontWeightBold" color={smoke[900]}>
                  {formatMoney(offer.price)}
                </Box>
              </Typography>
            </Box>
            <Typography color="textSecondary" variant="body2">
              {offer.waypoints.length} paradas •{' '}
              {noDecimalFormatDistance(offer.distance)}
            </Typography>
          </Box>
          <AcceptOfferButton
            size="medium"
            mx={4.5}
            handleAccept={() => {
              onAccept(offer);
            }}
            className={classes.acceptButton}
          />
        </Box>

        <Box mt={1} alignSelf="flex-start">
          <Typography color="textSecondary" variant="body2">
            {`Entregue todos os pacotes com sucesso e ganhe ${formatMoney(
              offer.price
            )}`}
          </Typography>
        </Box>
        {onMoreOffers ? (
          <Box mt={1} alignSelf="flex-start" onClick={onMoreOffers}>
            <Typography color="primary" variant="body2">
              <Box className={classes.moreOffers}>Mostrar outras ofertas</Box>
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

OfferDetails.propTypes = {
  offer: PropTypes.shape({
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    recommended: PropTypes.bool,
    opportunistic: PropTypes.bool,
    deadlineCollect: PropTypes.string.isRequired,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,
  onAccept: PropTypes.func.isRequired,
  onMoreOffers: PropTypes.func,
  onBack: PropTypes.func.isRequired
};

OfferDetails.defaultProps = {
  onMoreOffers: null
};

export default React.memo(OfferDetails);
