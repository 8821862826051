export default {
  UNDETERMINED_ERROR: 'Erro indeterminado',
  FILTER_ROUTES: 'Filtrar rotas',
  BUTTON_FILTER: 'Filtrar',
  ALREADY_ACCEPTED:
    'Alguém já aceitou a rota antes de você. Fique de olho para não perder a próxima.'
};

export const OFFER_CHANNEL_PUSH = 1;
export const OFFER_CHANNEL_AVAILABLE_OFFERS = 2;
