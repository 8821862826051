import * as Sentry from '@sentry/browser';

const dsn = process.env.REACT_APP_DSN_SENTRY;
const env = process.env.REACT_APP_ENV_SENTRY;

// Initializes the Sentry catch-all module.
Sentry.init({
  dsn,
  env,
  integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })]
});
