import { getCurrentUserInfo } from '@loggi/driver-authentication';
import { isFeatureSwitchEnabledForValue } from '@loggi/firebase-feature-switches';

const featureSwitches = {
  enableCognitoAuth: 'enableCognitoAuth',
  callAcceptOfferDirectlyPush: 'callAcceptOfferDirectlyPush',
  callAcceptOfferDirectlyRdShowcase: 'callAcceptOfferDirectlyRdShowcase'
};

/**
 * Possible values for featureSwitchValue:
 * LMCs separated by commas -> 'loggi_sp_sao_paulo,loggi_tower_0,loggi_tower_2'
 * one LMC only -> 'loggi_sp_sao_paulo'
 * boolean as string -> 'true'/'false'
 * boolean as boolean -> true/false
 */
export const featureSwitchEnabledForDriverLMC = featureSwitchValue => {
  const driverLMC = getCurrentUserInfo()?.lmcIdentification;
  return isFeatureSwitchEnabledForValue(driverLMC, featureSwitchValue);
};

export default featureSwitches;
