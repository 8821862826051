import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { ReactComponent as CheckIcon } from 'view/images/check-icon.svg';

const FilterItem = ({ item, selected, divider, onSelect }) => {
  const color = selected ? colors.bassis[700] : colors.smoke[900];

  return (
    <>
      <ListItem
        key={item.value}
        onClick={onSelect}
        style={{
          cursor: 'pointer',
          paddingTop: '16px',
          paddingBottom: '16px'
        }}
        cursor="pointer"
        disableGutters
      >
        <ListItemText>
          <Typography variant="body1">
            <Box color={color}>{item.label}</Box>
          </Typography>
        </ListItemText>
        {selected ? <CheckIcon height="12px" width="16px" /> : null}
      </ListItem>
      {divider ? null : <Divider />}
    </>
  );
};

FilterItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  divider: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default FilterItem;
