import { isEmbeddedInDriverApp } from '@loggi/drivers-app-version';

import restRequest from 'infra-operations/rest-request';
import {
  getCurrentUserInfo,
  storeCurrentUserInfo,
  removeCurrentUserInfo
} from 'infra-operations/storage/credential';
import isImpersonated from 'infra-operations/storage/impersonate';

export default async function deviceValidation() {
  const driverStoredData = getCurrentUserInfo();
  if (
    driverStoredData?.deviceValid &&
    window.driverAppBridge?.getDeviceId?.() === driverStoredData?.deviceId
  ) {
    return Promise.resolve(driverStoredData);
  }

  const deviceParams = isEmbeddedInDriverApp()
    ? {
        device_id: window.driverAppBridge?.getDeviceId?.(),
        push_token: window.driverAppBridge?.getPushToken?.()
      }
    : {};
  return restRequest()
    .url('api/v2/drivers/device/validate')
    .post({
      ...deviceParams,
      isImpersonation: isImpersonated()
    })
    .json()
    .then(driver => {
      removeCurrentUserInfo();

      if (driver && driver.pk) {
        const storedDriver = {
          isAuthenticated: true,
          deviceValid: true,
          deviceId: window.driverAppBridge?.getDeviceId?.(),
          ...driver
        };

        storeCurrentUserInfo(storedDriver);
        return Promise.resolve(storedDriver);
      }

      return Promise.reject(new Error({ isAuthenticated: false }));
    });
}
