import React from 'react';
import { colors } from '@loggi/mar';
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';

import {
  parseISODateToHours,
  getDiffInDaysBetweenDateAndNow
} from 'view/molecules/offer-card/util';
import { ReactComponent as InformationIcon } from './icons/information.svg';

const getFormattedDeadlineMessage = (startTime, deadlineTime) => {
  const days = getDiffInDaysBetweenDateAndNow(startTime);
  let startDayString = 'Hoje';

  if (days > 1) {
    const datetime = new Date(startTime);
    const dateFormatOptions = { month: '2-digit', day: '2-digit' };
    startDayString = datetime.toLocaleDateString('pt-br', dateFormatOptions);
  }

  if (days === 1) {
    startDayString = 'Amanhã';
  }

  return `${startDayString}, das ${parseISODateToHours(
    startTime
  )} às ${parseISODateToHours(deadlineTime)}`;
};

export const messages = {
  title: (startTimeDate, endTimeDate) => (
    <Typography variant="h5">
      Programe uma rota para <br />
      <b>{getFormattedDeadlineMessage(startTimeDate, endTimeDate)}</b>
    </Typography>
  ),
  subtitle: 'Você aceita agora e amanhã escolhe uma das rotas disponíveis.',
  loggiBase: 'Base Loggi',
  information: {
    title: (
      <Typography variant="title">
        Fique de olho na
        <b> janela de tempo</b>
      </Typography>
    ),
    subtitle:
      'Após aceitar a oferta, é importante chegar dentro do período combinado.'
  },
  button: 'Aceitar'
};

function CapacityReserveDetails({ offer, onAccept, onBack }) {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      bgcolor={colors.root[0]}
      color={colors.smoke[900]}
      p={2}
      data-testid="capacity-reserve-details"
    >
      <Box p={2} pl={0.5} display="flex" justifyContent="space-between">
        <IconButton onClick={onBack} data-testid="capacity-back-button">
          <ArrowBack style={{ color: colors.smoke[900] }} />
        </IconButton>
      </Box>
      <Box
        mb={4}
        px={2}
        display="flex"
        flexDirection="column"
        flex={1}
        overflow="auto"
      >
        <Box flexGrow={1} mb={2.5}>
          {messages.title(offer.startTimeCollect, offer.deadlineCollect)}
          <Box mt={0.5} color={colors.smoke[900]}>
            <Typography variant="body1">{messages.subtitle}</Typography>
          </Box>
          <Box my={3} borderLeft={2} borderColor={colors.smoke[500]} px={2}>
            <Typography variant="h6">
              <Box color={colors.smoke[900]} mb={0.5}>
                {messages.loggiBase} • {offer.waypoints[0]?.neighborhood}
              </Box>
            </Typography>
            <Typography variant="body1">
              <Box color={colors.smoke[700]}>
                {offer.waypoints[0]?.loggiAddress}
              </Box>
            </Typography>
          </Box>
        </Box>
        <Box
          borderRadius={16}
          textAlign="left"
          bgcolor={colors.blue[50]}
          color={colors.blue[900]}
          px={3}
          py={4}
          mb={10}
        >
          <InformationIcon />
          <Typography variant="body1">{messages.information.title}</Typography>
          <Typography variant="body2">
            {messages.information.subtitle}
          </Typography>
        </Box>
        <Box
          border={0}
          px={4}
          py={2}
          bgcolor="white"
          position="fixed"
          bottom="0"
          left="0"
          width="100%"
          textAlign="center"
        >
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            onClick={() => {
              onAccept(offer);
            }}
          >
            {messages.button}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

CapacityReserveDetails.propTypes = {
  offer: PropTypes.shape({
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    recommended: PropTypes.bool,
    deadlineCollect: PropTypes.string.isRequired,
    startTimeCollect: PropTypes.string.isRequired,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,
  onAccept: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default CapacityReserveDetails;
