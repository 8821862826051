import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';

import {
  parseISODateToHours,
  getDiffInDaysBetweenDateAndNow
} from 'view/molecules/offer-card/util';

const getOfferDay = startDate => {
  const days = getDiffInDaysBetweenDateAndNow(startDate);
  if (days > 1) {
    return `para daqui ${days} dias!`;
  }
  if (days === 0) {
    return 'para as próximas horas!';
  }
  return 'para amanhã!';
};

export const messages = {
  subtitle: 'Disponível entre',
  offerInfo: offer => {
    return {
      title: (
        <Typography variant="h6">
          Programe uma rota <br />
          <b>{getOfferDay(offer.startTimeCollect)}</b>
        </Typography>
      ),
      timeWindow: `${parseISODateToHours(
        offer.startTimeCollect
      )} e ${parseISODateToHours(offer.deadlineCollect)}`,
      address: `Loggi • ${offer.waypoints[0]?.neighborhood}`
    };
  }
};

const OfferCardCapacityReserve = ({ offer }) => {
  return (
    <Box
      bgcolor={colors.blue[50]}
      color={colors.blue[900]}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={3}
      borderRadius={14}
    >
      {messages.offerInfo(offer).title}
      <Box mt={1}>
        <Typography variant="caption" component="span">
          {messages.subtitle}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5">
          <b>{messages.offerInfo(offer).timeWindow}</b>
        </Typography>
        <Typography variant="body2">
          {messages.offerInfo(offer).address}
        </Typography>
      </Box>
    </Box>
  );
};

OfferCardCapacityReserve.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    recommended: PropTypes.bool,
    deadlineCollect: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired
};

export default OfferCardCapacityReserve;
