import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaxDevicesPage from './max-devices';
import ForbiddenDevicePage from './forbidden';
import AlreadyRegisteredPage from './already-registered';

export const deviceRegistrationErrorsDefault = errorDevice =>
  `DeviceValidationError not implemented: ${errorDevice}`;

export const deviceRegistrationErrorsMap = {
  maxDevicesError: 'max_num_allowed_devices',
  forbiddenDeviceError: 'forbidden_device',
  alreadyRegisteredDeviceError: 'already_associated_device'
};

export default function DeviceValidationError({ errorDevice, email }) {
  return (
    {
      [deviceRegistrationErrorsMap.maxDevicesError]: (
        <MaxDevicesPage email={email} />
      ),
      [deviceRegistrationErrorsMap.forbiddenDeviceError]: (
        <ForbiddenDevicePage />
      ),
      [deviceRegistrationErrorsMap.alreadyRegisteredDeviceError]: (
        <AlreadyRegisteredPage />
      )
    }[errorDevice] || <Box>{deviceRegistrationErrorsDefault(errorDevice)}</Box>
  );
}
DeviceValidationError.propTypes = {
  errorDevice: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};
