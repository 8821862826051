import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import { ReactComponent as EmailSentIcon } from 'view/assets/svg/email-sent.svg';
import LoadingTemplate from 'view/templates/loading';

export const maxDevicesPageTexts = {
  title: 'Enviamos um e-mail para validar esse dispositivo',
  subtitle:
    'Esse dispositivo celular ainda não está autorizado, enviamos um email para',
  subtitleAction: 'para validar esse dispositivo.',
  openGmail: 'Abrir Gmail',
  gmailLink: 'https://mail.google.com/mail/',
  logout: 'Sair da conta'
};

export default function MaxDevicesPage({ email }) {
  const [isUiLoading, setIsUILoading] = useState(false);
  const { signOut } = useAmplifyAuth();

  const logout = async () => {
    setIsUILoading(true);
    await signOut();
  };

  if (isUiLoading) {
    return <LoadingTemplate />;
  }

  return (
    <Box pt={8} p={3} height="100%" display="flex" flexDirection="column">
      <Box flexGrow={1}>
        <EmailSentIcon />
        <Box my={1}>
          <Typography variant="h5">{maxDevicesPageTexts.title}</Typography>
        </Box>
        <Typography variant="subtitle2">
          {maxDevicesPageTexts.subtitle}{' '}
          <Box component="span" fontWeight="bold">
            {email?.substring(0, 2)}******
            {email?.substring(email?.indexOf('@'))}
          </Box>{' '}
          {maxDevicesPageTexts.subtitleAction}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => window.open(maxDevicesPageTexts.gmailLink)}
        >
          {maxDevicesPageTexts.openGmail}
        </Button>
        <Box pt={2} pb={3} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            onClick={logout}
          >
            {maxDevicesPageTexts.logout}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

MaxDevicesPage.propTypes = {
  email: PropTypes.string.isRequired
};
