import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackHeader = () => {
  const history = useHistory();

  const handleBackButtonClick = () => {
    history.goBack();
  };

  return (
    <Box display="flex" justifyContent="space-between" pb={4}>
      <ArrowBackIcon data-testid="backButton" onClick={handleBackButtonClick} />
    </Box>
  );
};

export default React.memo(BackHeader);
