import { makeStyles } from '@material-ui/core';

export const verificationCodeMsgs = {
  title: 'Confirme seu celular',
  subtitle: 'Enviamos um código para phone_user. Digite abaixo para continuar.',
  code: 'Código',
  resendCode: 'Reenviar SMS',
  verify: 'Confirmar'
};

export const useStyles = makeStyles(theme => ({
  contentText: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  disableLink: {
    color: theme.palette.grey[100],
    cursor: 'not-allowed'
  }
}));
