import React from 'react';
import PropTypes from 'prop-types';
import { formActions } from 'view/state-machines/utils/form';
import Form from '../base-form';
import loginTexts from './constants';

export default function LoginTemplate({ LoginMachine }) {
  const { current, send } = LoginMachine;
  const { fields } = current.context;
  const invalid = current.matches('editing.invalid');
  const submiting = current.matches('submiting');
  const disableSubmit = invalid || submiting;

  const handleChange = data => send(formActions.change, data);
  const handleSubmit = event => {
    event.preventDefault();
    send(formActions.submit);
  };

  const field = {
    name: 'mobileNumber',
    placeholder: loginTexts.phone,
    type: 'tel'
  };

  return (
    <Form onChange={handleChange} fields={fields}>
      <Form.Header title={loginTexts.title} subtitle={loginTexts.subtitle} />

      <Form.Input key="mobileNumber" field={field} />

      <Form.Submit
        text={loginTexts.login}
        disabled={disableSubmit}
        onClick={handleSubmit}
      />
    </Form>
  );
}

LoginTemplate.propTypes = {
  LoginMachine: PropTypes.shape({
    current: PropTypes.shape({
      matches: PropTypes.func.isRequired,
      context: PropTypes.shape({
        fields: PropTypes.shape({
          mobileNumber: PropTypes.shape({
            touched: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
            errors: PropTypes.arrayOf(PropTypes.string).isRequired
          })
        })
      })
    }).isRequired,
    send: PropTypes.func.isRequired
  }).isRequired
};
