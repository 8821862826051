const patternBR = new RegExp(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/);

export default function isMobilePhoneValid(value) {
  if (!value) {
    return false;
  }

  const phone = value.replace(/[^0-9]/g, '');
  return patternBR.test(phone);
}
