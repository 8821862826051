import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { formatMoney, parseISODateToHours } from './util';
import clockIcon from './clock_icon.svg';
import { OfferTag } from './common';

function OfferOpportunistic({ offer, originDistance }) {
  const deadlineInfo = () => {
    let message = '';

    const deadline = parseISODateToHours(offer.deadlineCollect);

    if (deadline) {
      message = message.concat(`Ir hoje, até ${deadline}`);
    }

    return <Typography variant="body1">{message}</Typography>;
  };

  return (
    <Box>
      <Box
        p={2.5}
        textAlign="left"
        pb={8}
        data-testid="offer-opportunist-header"
      >
        <Typography variant="h4">
          <Box fontWeight="fontWeightBold" display="inline">
            Nova oferta
          </Box>{' '}
          no seu caminho,{' '}
          <Box fontWeight="fontWeightBold" display="inline">
            aproveite!
          </Box>
        </Typography>
      </Box>

      <Box
        p={2.5}
        justifyContent="space-evenly"
        textAlign="left"
        alignItems="left"
        pb={2}
      >
        <OfferTag message={offer.tagMessage} bgcolor={offer.tagColor} mb={2} />
        <Box pb={1}>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold" display="inline">
              {formatMoney(offer.price)}
            </Box>
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="body1">
            {offer.waypoints[0].neighborhood} - {offer.waypoints[0].city}
          </Typography>
          {deadlineInfo()}
        </Box>
        <Box pb={10}>
          <Typography variant="caption" color="textSecondary">
            {offer.waypoints.length} paradas • {originDistance} de você
          </Typography>
        </Box>
        <Box
          p={2.5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="#F5F8FA"
          data-testid="offer-deadline-info"
        >
          <Box height={25} width={25} pr={6}>
            <img
              src={clockIcon}
              height={25}
              width={25}
              alt="Delivery the collected packages to the agency today"
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body1">
              Entregue os pacotes{' '}
              <Box fontWeight="fontWeightBold" display="inline">
                hoje até {offer.closingTime} na Base Loggi • {offer.name}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

OfferOpportunistic.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    deadlineCollect: PropTypes.string,
    shipperName: PropTypes.string,
    closingTime: PropTypes.string,
    name: PropTypes.string,
    tagMessage: PropTypes.string,
    tagColor: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,

  originDistance: PropTypes.string.isRequired
};

export default React.memo(OfferOpportunistic);
