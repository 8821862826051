import React from 'react';

import { Box, CircularProgress, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';

const Loading = ({ visibleBackButton, onClose }) => {
  return (
    <Box
      height="100%"
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {visibleBackButton && (
        <Box position="fixed" left={0} top={0}>
          <IconButton data-testid="onBackIconButton" onClick={onClose}>
            <CloseIcon style={{ color: colors.blue[500] }} />
          </IconButton>
        </Box>
      )}
      <Box color={colors.blue[500]} data-testid="loadingContent" mb={20}>
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  );
};
Loading.propTypes = {
  visibleBackButton: PropTypes.bool,
  onClose: PropTypes.func
};

Loading.defaultProps = {
  visibleBackButton: false,
  onClose: null
};

export default React.memo(Loading);
