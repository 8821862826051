/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Typography, Drawer } from '@material-ui/core';
import { colors } from '@loggi/mar';
import test from './icons/capacity-reserve.png';

const localStorageKey = 'notified-of-capacity-reserve';

export const removeDriverNotifiedOfCapacityReserve = () => {
  localStorage.removeItem(localStorageKey);
};

export const setDriverNotifiedOfCapacityReserve = () => {
  localStorage.setItem(localStorageKey, true);
};

const getDriverNotifiedOfCapacityReserve = () => {
  return JSON.parse(localStorage.getItem(localStorageKey));
};

export const messages = {
  title: 'Novidade',
  notificationTitle: (
    <Typography variant="h5">
      Agora você pode <b>programar</b> uma rota para retirar <b>amanhã</b>
    </Typography>
  ),
  notificationSubtitle:
    'Você aceita agora e amanhã escolhe uma das rotas disponíveis.',
  button: 'Entendi'
};

export default function CapacityReserveNotificationDrawer({
  hasCapacityReserve
}) {
  const driverNotNotified = !getDriverNotifiedOfCapacityReserve();
  const [shouldNotifyDriver, setShouldNotifyDriver] = useState(
    hasCapacityReserve && driverNotNotified
  );

  useEffect(() => {
    if (hasCapacityReserve && driverNotNotified) {
      setShouldNotifyDriver(true);
    }
  }, [hasCapacityReserve]);

  return (
    <Drawer
      anchor="bottom"
      open={shouldNotifyDriver}
      onClose={() => setDrawerState(STATE_ENUM.CLOSED)}
      PaperProps={{
        elevation: 0,
        style: { borderTopRightRadius: '16px', borderTopLeftRadius: '16px' }
      }}
      data-testid="capacity-notification-drawer"
    >
      <Box px={2.5} pb={2.5} color={colors.smoke[900]}>
        <Box py={1.5} textAlign="center">
          <img src={test} />
        </Box>
        <Typography variant="body1">{messages.title}</Typography>
        <Box pt={2} color={colors.smoke[800]}>
          {messages.notificationTitle}
          <Box pt={1}>
            <Typography variant="body1">
              {messages.notificationSubtitle}
            </Typography>
          </Box>
        </Box>

        <Box pt={5}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            onClick={() => {
              setDriverNotifiedOfCapacityReserve();
              setShouldNotifyDriver(false);
            }}
            data-testid="capacity-notification-drawer-button"
          >
            {messages.button}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

CapacityReserveNotificationDrawer.propTypes = {
  hasCapacityReserve: PropTypes.bool.isRequired
};
