import React from 'react';
import { Box, Typography } from '@material-ui/core';

import TEXT from './constants';

export default function Unauthenticated() {
  return (
    <Box
      p="15px"
      textAlign="center"
      minHeight="100vh"
      alignItems="center"
      display="flex"
      justifyContent="center"
    >
      <Typography>{TEXT.YOU_NEED_BE_LOGGED}</Typography>
    </Box>
  );
}
