import React, { createContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import camelcaseKeys from 'camelcase-keys';
import {
  getSplittedValuesFromFS,
  isFeatureSwitchEnabledForValue,
  getRemoteConfigJSON
} from './utils';

const FirebaseContext = createContext();

function FirebaseConfigProvider({ children }) {
  const remoteConfig = firebase.remoteConfig();
  const [config, setConfig] = useState({});
  const value = useMemo(() => ({ config }), [config]);

  useEffect(() => {
    let unmounted = false;

    (async () => {
      await remoteConfig.fetchAndActivate();

      const fsInSnakeCase = remoteConfig.getAll();
      const fs = camelcaseKeys(fsInSnakeCase);
      if (!unmounted) setConfig(fs);
    })();

    return () => {
      unmounted = true;
    };
  }, [remoteConfig]);

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
}

FirebaseConfigProvider.propTypes = { children: PropTypes.node.isRequired };

export const errorMessage =
  'useFeatureSwitch hook must be used inside FirebaseConfigProvider.';

export const remoteConfigErrorMessage =
  'useRemoteConfig hook need a default value.';

const featureSwitchSource = {
  remote: 'remote',
  default: 'default'
};

/**
 * Get the feature switch value from the list of remote config or default config
 * @param {*} listOfFeatureSwitches list of feature switches (remote config or default config)
 *                                  - remote config: obtained from fetching values on firebase
 *                                  - default config: defined in application/target
 * @param {*} featureSwitchName name of the feature switch we want to get the value of
 * @param {*} source source of the feature switch value
 * @returns The value of FS, if it is a boolean, it returns a true or false, otherwise it returns the value configured
 */
function getFeatureSwitchValue({
  listOfFeatureSwitches,
  featureSwitchName,
  source = featureSwitchSource.remote
}) {
  const booleans = { true: true, false: false };
  const value =
    source === featureSwitchSource.remote
      ? listOfFeatureSwitches[featureSwitchName]._value
      : listOfFeatureSwitches[featureSwitchName];

  if (value in booleans) {
    return booleans[value];
  }

  return value;
}

function useFeatureSwitch(featureSwitch) {
  const fsContext = React.useContext(FirebaseContext);

  if (!fsContext) throw new Error(errorMessage);

  if (!('config' in fsContext)) return false;

  const { defaultConfig = {} } = firebase.remoteConfig() || {};

  const fs = fsContext.config;

  if (featureSwitch in fs) {
    return getFeatureSwitchValue({
      listOfFeatureSwitches: fs,
      featureSwitchName: featureSwitch
    });
  }

  if (featureSwitch in defaultConfig) {
    return getFeatureSwitchValue({
      listOfFeatureSwitches: defaultConfig,
      featureSwitchName: featureSwitch,
      source: featureSwitchSource.default
    });
  }

  return false;
}

function useRemoteConfig(remoteConfigFlag, defaultValue) {
  const fsContext = React.useContext(FirebaseContext);

  if (!fsContext) throw new Error(errorMessage);

  if (!defaultValue) throw new Error(remoteConfigErrorMessage);
  if (!('config' in fsContext)) return defaultValue;

  const configs = fsContext.config;
  if (remoteConfigFlag in configs) {
    return getRemoteConfigJSON(configs[remoteConfigFlag]._value, defaultValue);
  }

  return defaultValue;
}

export default FirebaseConfigProvider;
export {
  useFeatureSwitch,
  FirebaseContext,
  getSplittedValuesFromFS,
  isFeatureSwitchEnabledForValue,
  useRemoteConfig
};
