import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@material-ui/core';
import { useForm } from '../context';

const Input = ({ field, render }) => {
  const context = useForm();

  if (render) {
    return render(context);
  }

  const { fieldError, handleBlur, handleChange, fields } = context;

  const value = fields && fields[field.name] && fields[field.name].value;

  const commonTextFieldProps = {
    value,
    id: field.name,
    name: field.name,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
    onBlur: handleBlur,
    helperText: fieldError(field.name),
    error: !!fieldError(field.name),
    type: field.type,
    inputProps: { ...field.inputProps, 'data-testid': field.name },
    InputProps: field.InputProps,
    disabled: field.disabled
  };

  return (
    <Box pb={2.5} key={field.name}>
      <TextField
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...commonTextFieldProps}
        placeholder={field.placeholder}
        label={field.label}
      />
    </Box>
  );
};

Input.propTypes = {
  render: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    inputProps: PropTypes.shape({}),
    InputProps: PropTypes.shape({}),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool
      })
    ),
    label: PropTypes.node,
    disabled: PropTypes.bool
  })
};

Input.defaultProps = {
  field: {},
  render: null
};

export default Input;
