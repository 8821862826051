import { getDriverAppBridge } from './app-bridge';

const OFFER_ACCEPT_CLICK = 'offer_list_accept_click';
const OFFER_ACCEPT_SUCCESS = 'offer_list_accept_success';
const OFFER_ACCEPT_ERROR = 'offer_list_accept_error';
const OFFER_ACCEPT_REST_CLICK = 'offer_list_rest_accept_click';
const OFFER_ACCEPT_REST_SUCCESS = 'offer_list_rest_accept_success';
const OFFER_ACCEPT_REST_ERROR = 'offer_list_rest_accept_error';
const BUTTON_ABORT_LOADING = 'button_abort_loading';
export const OFFER_LIST_ACCESS = 'offer_list_access';
export const OFFER_LIST_ACCESS_SOURCE_DETAILS = 'details';
export const OFFER_LIST_ACCESS_SOURCE_SHOWALL_SHOWCASE = 'show_all_showcase';
export const OFFER_LIST_ACCESS_SOURCE_CARROUSSEL_BUTTON = 'carroussel_button';
export const OFFER_LIST_ACCESS_SOURCE_HOME_BUTTON = 'home_button';

/* Register events in Google Analystics by calling the App api to track event.
 * Runs only on production and only if embbedded in a web view.
 * Each function logs a specific event with a paramter composed of the itinerary
 * selected by the user and its index in the list of offers
 */
export function registerOfferListAcceptClick(index, itineraryId) {
  if (process.env.NODE_ENV === 'production' && getDriverAppBridge()) {
    getDriverAppBridge().trackEvent(
      OFFER_ACCEPT_CLICK,
      `${index},${itineraryId}`
    );
  }
}

export function registerOfferListAcceptSuccess(index, itineraryId) {
  if (process.env.NODE_ENV === 'production' && getDriverAppBridge()) {
    getDriverAppBridge().trackEvent(
      OFFER_ACCEPT_SUCCESS,
      `${index},${itineraryId}`
    );
  }
}

export function registerOfferListAcceptError(index, itineraryId) {
  if (process.env.NODE_ENV === 'production' && getDriverAppBridge()) {
    getDriverAppBridge().trackEvent(
      OFFER_ACCEPT_ERROR,
      `${index},${itineraryId}`
    );
  }
}

export function registerOfferListAcceptRestClick(index, demandId) {
  if (process.env.NODE_ENV === 'production' && getDriverAppBridge()) {
    getDriverAppBridge().trackEvent(
      OFFER_ACCEPT_REST_CLICK,
      `${index},${demandId}`
    );
  }
}

export function registerOfferListAcceptRestSuccess(index, demandId) {
  if (process.env.NODE_ENV === 'production' && getDriverAppBridge()) {
    getDriverAppBridge().trackEvent(
      OFFER_ACCEPT_REST_SUCCESS,
      `${index},${demandId}`
    );
  }
}

export function registerOfferListAcceptRestError(index, demandId) {
  if (process.env.NODE_ENV === 'production' && getDriverAppBridge()) {
    getDriverAppBridge().trackEvent(
      OFFER_ACCEPT_REST_ERROR,
      `${index},${demandId}`
    );
  }
}

export function registerOfferListAccess(source) {
  if (process.env.NODE_ENV === 'production' && getDriverAppBridge()) {
    getDriverAppBridge().trackEvent(OFFER_LIST_ACCESS, source);
  }
}

export function registerAbortLoading(source) {
  if (getDriverAppBridge()) {
    getDriverAppBridge().trackEvent(BUTTON_ABORT_LOADING, source);
  }
}
