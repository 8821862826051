import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  submitButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1.5)
    },
    marginTop: 'auto'
  },
  submitIcon: {
    width: '56px',
    height: '56px',
    minWidth: 'auto'
  },
  list: {
    padding: 0
  },
  listIcon: {
    minWidth: 'auto',
    paddingRight: theme.spacing(1)
  },
  listItem: {
    padding: 0
  }
}));

export default useStyles;
