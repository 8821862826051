import api from 'infra/api';
import geolocation from './geolocation';

export const getDriverOffersQuery = `
  query GetDriverOffers($filterType: Int, $filterValues: [String], $latitude: Float, $longitude: Float) {
    getDriverOffers(filterType: $filterType, filterValues: $filterValues, latitude: $latitude, longitude: $longitude) {
      itineraryId
      distance
      price
      steps
      deadlineCollect
      waypoints {
        city
        neighborhood
        indexDisplay
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export default async function getAvailableOffers(
  filterType,
  filterValue,
  showcase = false
) {
  const pos = await geolocation();

  let searchParams = `?latitude=${pos.latitude}&longitude=${
    pos.longitude
  }&showcase=${showcase}`;
  if (filterType && filterValue) {
    searchParams += `&filter_type=${filterType}&filter_values=${filterValue}`;
  }

  const url = `/drivers/v2/offer_list/${searchParams}`;

  return api()
    .url(url)
    .get()
    .json();
}
