import { Machine } from 'xstate';
import * as yup from 'yup';

import requestPhoneVerification from 'infra-operations/services/request-phone-verification';
import * as storage from 'infra-operations/storage/credential';

import { driverInfoFormMessages } from './utils/errors-message';
import {
  formState,
  formActions,
  emptyField,
  formValid,
  formInvalid,
  changeField,
  mergeValidationErrors,
  validateFields,
  initialContextFromFieldsValues,
  fieldsValuesFromContext,
  sendErrorNotification
} from './utils/form';
import {
  mobilePhoneFormatter,
  removeMobilePhoneFormatter
} from './utils/form/formatters/mobile-phone';
import isMobilePhoneValid from './utils/form/validators/mobile-phone';

export const initialLoginContext = {
  fields: {
    mobileNumber: emptyField()
  },
  errors: []
};

const driverLoginSchema = yup.object().shape({
  mobileNumber: yup
    .string()
    .test(
      'mobileNumber',
      driverInfoFormMessages.mobileNumber,
      isMobilePhoneValid
    )
    .required(driverInfoFormMessages.invalidMobileNumber)
    .transform(mobilePhoneFormatter)
});

export const guards = {
  formValid,
  formInvalid
};

export const requestPhoneVerificationQuery = context =>
  requestPhoneVerification({
    mobileNumber: removeMobilePhoneFormatter(context.fields.mobileNumber.value)
  });

export const actions = {
  changeField,
  validateFields: validateFields(driverLoginSchema),
  sendErrorNotification,
  mergeValidationErrors,
  setCredentials: context => {
    const data = fieldsValuesFromContext(context);
    return storage.storeCurrentUserInfo({
      mobileNumber: data.mobileNumber
    });
  }
};

const services = {
  requestPhoneVerificationQuery
};

const LoginMachine = Machine(
  {
    id: 'login',
    initial: formState.editing,
    context: initialContextFromFieldsValues(
      initialLoginContext,
      driverLoginSchema
    ),
    on: {
      EDITING: formState.editing,
      submitting: formState.submitting,
      success: formState.success
    },
    states: {
      [formState.editing]: {
        initial: formState.invalid,
        states: {
          [formState.invalid]: {
            on: {
              '': [{ target: formState.valid, cond: 'formValid' }]
            }
          },
          [formState.valid]: {
            on: {
              '': [{ target: formState.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          [formActions.submit]: {
            target: formState.submitting,
            cond: 'formValid'
          },
          [formActions.change]: {
            actions: ['changeField', 'validateFields']
          }
        }
      },
      [formState.submitting]: {
        entry: 'setCredentials',
        invoke: {
          id: 'requestPhoneVerificationQuery',
          src: 'requestPhoneVerificationQuery',
          onDone: {
            target: formState.success
          },
          onError: {
            target: formState.editing,
            actions: ['mergeValidationErrors', 'sendErrorNotification']
          }
        }
      },
      [formState.success]: {
        type: 'final'
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default LoginMachine;
