import React from 'react';
import PropTypes from 'prop-types';
import { Router, Switch } from 'react-router-dom';
import history from 'operations/history';

const Routes = props => {
  const { children } = props;

  return (
    <Router history={history}>
      <Switch>{children}</Switch>
    </Router>
  );
};

Routes.propTypes = {
  children: PropTypes.node.isRequired
};

export default Routes;
