import {
  registerOfferListAcceptClick,
  registerOfferListAcceptSuccess,
  registerOfferListAcceptError,
  registerOfferListAcceptRestClick,
  registerOfferListAcceptRestSuccess,
  registerOfferListAcceptRestError
} from 'infra/services/analytics';
import { openDeepLink } from 'infra/services/app-bridge';
import { OFFER_CHANNEL_AVAILABLE_OFFERS } from 'view/pages/offers/constants';
import apiLoggiWeb from 'infra/api';
import apiAllocation from 'infra/api/allocation';
import graphqlRequest from '../graphql-request';
import geolocation from './geolocation';

/**
 * Offer channel send when offer mutation has been called
 */
const offerChannelAvailableOffers = 'offer_listing';

const acceptOfferMutation = `
  mutation AcceptOfferMutation($itineraryId: Int!, $offerChannel: String) {
    acceptOfferMutation(input: {
          itineraryId: $itineraryId,
          offerChannel: $offerChannel
      }) {
        isSuccessfully: success
        errors {
            message
        }
    }
  }
`;

/**
 * Performs a API request to acceptOfferMutation
 *
 * @param {Object} variables - The variables that be used in graphql request
 * @param {int} variables.itineraryId - Of offer to be accepted
 * @returns {Promise}
 */
export default function acceptOffer(variables) {
  return graphqlRequest({
    query: acceptOfferMutation,
    variables
  }).then(({ data, errors }) => {
    const response = data.acceptOfferMutation;
    if (response && response.isSuccessfully) {
      return response;
    }

    let errorResponse;
    if (response && 'errors' in response) {
      errorResponse = { errors: response.errors };
      return Promise.reject(errorResponse);
    }
    errorResponse = { errors };
    return Promise.reject(errorResponse);
  });
}

export async function acceptOfferRest(
  offerId,
  pickupId,
  offerChannel,
  ignoreProxy = false
) {
  const position = await geolocation();
  const body = {
    offer_id: offerId,
    pickup_id: pickupId,
    offer_channel: offerChannel,
    driver_position: {
      lat: position?.latitude,
      lon: position?.longitude
    }
  };

  const urls = {
    loggiWeb: '/offer/accept',
    allocation: '/v1/offerdemand/acceptoffer'
  };

  const api = ignoreProxy ? apiAllocation() : apiLoggiWeb();
  const url = ignoreProxy ? urls.allocation : urls.loggiWeb;

  return api
    .url(url)
    .post(body)
    .json();
}

export function acceptOfferService(
  { itineraryId, demandId, id },
  index,
  ignoreProxy = false
) {
  if (itineraryId) {
    registerOfferListAcceptClick(index, itineraryId);
    return acceptOffer({
      itineraryId,
      offerChannel: offerChannelAvailableOffers
    })
      .then(() => {
        registerOfferListAcceptSuccess(index, itineraryId);
        openDeepLink();
      })
      .catch(responseError => {
        registerOfferListAcceptError(index, itineraryId);

        let message = 'Erro indeterminado';
        if ('errors' in responseError && responseError.errors.length) {
          message = responseError.errors[0].message;
        }

        throw new Error(message);
      });
  }

  registerOfferListAcceptRestClick(index, demandId);

  return acceptOfferRest(
    id,
    demandId,
    OFFER_CHANNEL_AVAILABLE_OFFERS,
    ignoreProxy
  )
    .then(() => {
      registerOfferListAcceptRestSuccess(index, demandId);
      openDeepLink();
    })
    .catch(err => {
      registerOfferListAcceptRestError(index, demandId);
      throw new Error(err.message);
    });
}
