import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { colors } from '@loggi/mar';
import getDistance from 'infra/services/get-distance';
import { parseDistance, noDecimalFormatDistance } from './util';
import TEXT from './constants';
import OfferDirect from './offer-card-direct';
import OfferPricing from './offer-card-pricing';
import OfferCombined from './offer-card-combined';
import OfferOpportunistic from './offer-card-opportunistic';
import { AcceptOfferButton, RejectOfferButton } from './common';
import OfferTrip from './offer-card-trip';

function OfferCard({ onAccept, offer, onReject, hasReject }) {
  const [originDistance, setDistance] = useState(null);

  const handleAccept = () =>
    onAccept(offer.itineraryId, offer.demandId, offer.id);

  const handleReject = () => onReject();

  const point = offer.waypoints[0].coordinates;

  useEffect(() => {
    if (offer.distanceDriverFirstService) {
      setDistance(noDecimalFormatDistance(offer.distanceDriverFirstService));
    } else {
      getDistance(point)
        .then(dist => setDistance(parseDistance(dist)))
        .catch(() => setDistance('-')); // TODO: verify this message with UX Writter
    }
  }, [point, offer.distanceDriverFirstService]);

  const acceptButton = () => {
    let message = TEXT.BUTTON_ACCEPT_OFFER;
    if (offer.directAttribution) {
      message = TEXT.BUTTON_ACCEPT_OFFER_DIRECT;
    }
    if (offer.combined) {
      message = TEXT.BUTTON_RESERVE_OFFER;
    }
    const size = offer.directAttribution ? 10 : 1.5;

    return (
      <Box>
        <AcceptOfferButton
          handleAccept={handleAccept}
          message={message}
          mx={size}
        />
      </Box>
    );
  };

  const selectCards = () => {
    if (offer.directAttribution) {
      return <OfferDirect offer={offer} originDistance={originDistance} />;
    }
    if (offer.combined) {
      return (
        <OfferCombined
          offer={offer}
          originDistance={originDistance}
          onReject={onReject}
          hasReject={hasReject}
        />
      );
    }
    if (offer.opportunistic) {
      return (
        <OfferOpportunistic offer={offer} originDistance={originDistance} />
      );
    }
    if (offer.trip) {
      return <OfferTrip offer={offer} originDistance={originDistance} />;
    }

    return (
      <OfferPricing
        offer={offer}
        onAccept={onAccept}
        onReject={onReject}
        hasReject={hasReject}
        originDistance={originDistance}
      />
    );
  };

  return (
    <Box
      bgcolor={colors.root[0]}
      height={window.innerHeight * 0.95}
      maxHeight={window.innerHeight * 0.95}
      display="flex"
      flexDirection="column"
    >
      {selectCards()}
      <Box
        display="flex"
        justifyContent="space-between"
        textAlign="center"
        alignItems="flex-end"
        flexGrow={1}
        pt={2}
        pb={1}
      >
        {hasReject && (
          <Box px={1} data-testid="offer-reject-button">
            <RejectOfferButton handleReject={handleReject} />
          </Box>
        )}
        <Box px={1}>{acceptButton()}</Box>
      </Box>
    </Box>
  );
}

OfferCard.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    recommended: PropTypes.bool,
    deadlineCollect: PropTypes.string.isRequired,
    startTimeCollect: PropTypes.string,
    directAttribution: PropTypes.bool,
    combined: PropTypes.bool,
    opportunistic: PropTypes.bool,
    trip: PropTypes.bool,
    shipperName: PropTypes.string,
    tagMessage: PropTypes.string,
    tagColor: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired,
    distanceDriverFirstService: PropTypes.number
  }).isRequired,

  // (itineraryId) => {}
  onAccept: PropTypes.func.isRequired,

  onReject: PropTypes.func,

  hasReject: PropTypes.bool
};

OfferCard.defaultProps = {
  onReject: () => null,
  hasReject: false
};

export default React.memo(OfferCard);
