import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Divider,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import {
  formatMoney,
  parseDistance,
  hasExtraSteps,
  parseExtraSteps,
  useStyles,
  parseISODateToHours
} from './util';
import TEXT from './constants';
import OfferDetail from './offer-detail';
import { RoundListMarker, OfferTag } from './common';
import recommendedIcon from './recommended_icon.svg';

function OfferPricing({
  onAccept,
  offer,
  onReject,
  hasReject,
  originDistance
}) {
  const classes = useStyles();
  const [isRouteDetailsOpen, setIsRouteDetailsOpen] = useState(false);
  const hasDistance = originDistance !== null;

  const handleStateRouteDetails = () => {
    setIsRouteDetailsOpen(
      currentIsRouteDetailsOpen => !currentIsRouteDetailsOpen
    );
  };

  return (
    <Box>
      {offer.recommended && (
        <Box
          p={2.5}
          pb={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          data-testid="offer-recommended"
        >
          <Box height={37} width={37} pr={6}>
            <img
              src={recommendedIcon}
              height={37}
              width={37}
              alt="This route is recommended"
            />
          </Box>
          <Box height={37} display="flex" alignItems="center">
            <Typography variant="body1" color="textSecondary" fontWeight="bold">
              Rota <b>Indicada!</b> Altas chances de você{' '}
              <strong style={{ color: '#00D494' }}>
                entregar mais pacotes.
              </strong>
            </Typography>
          </Box>
        </Box>
      )}

      <Box p={2.5}>
        <OfferTag message={offer.tagMessage} bgcolor={offer.tagColor} mb={2} />
        <Box textAlign="center" pb={1}>
          <Typography
            style={{ fontWeight: 600 }}
            variant="body1"
            color="textSecondary"
          >
            {TEXT.OFFER_VALUE}
          </Typography>
          <Box>
            <Typography variant="h2">{formatMoney(offer.price)}</Typography>
          </Box>
        </Box>

        {offer.deadlineCollect && (
          <Box textAlign="center" p={1.5} data-testid="offer-deadline-collect">
            <Typography
              style={{ fontWeight: 600 }}
              variant="body1"
              color="textSecondary"
            >
              {TEXT.DEADLINE_COLLECT}
            </Typography>
            <Typography variant="h4">
              <Box fontWeight="fontWeightMedium">
                {parseISODateToHours(offer.deadlineCollect)}
              </Box>
            </Typography>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-evenly"
          textAlign="center"
          alignItems="center"
          pb={3}
        >
          <Box>
            <Typography
              style={{ fontWeight: 600 }}
              variant="body1"
              color="textSecondary"
            >
              {TEXT.FIRST_STOP_DISTANCE_AT}
            </Typography>
            <Box>
              {hasDistance ? (
                <Typography variant="h4">{originDistance}</Typography>
              ) : (
                <Box
                  textAlign="center"
                  color={colors.blue[500]}
                  data-testid="offer-distance-loading"
                >
                  <CircularProgress size={20} color="inherit" />
                </Box>
              )}
            </Box>
          </Box>

          <Divider orientation="vertical" style={{ height: '54px' }} />

          <Box>
            <Typography
              style={{ fontWeight: 600 }}
              variant="body1"
              color="textSecondary"
            >
              {TEXT.TOTAL_DISTANCE}
            </Typography>
            <Typography variant="h4">
              {parseDistance(offer.distance)}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" pb={2} alignItems="center">
            <Box
              width="100px"
              pt={0.25}
              pr={3}
              textAlign="right"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography variant="body1" color="textSecondary">
                {TEXT.START}:
              </Typography>

              <RoundListMarker
                bgcolor={colors.blue[500]}
                txtcolor={colors.root[0]}
                index={offer.waypoints[0].indexDisplay}
              />
            </Box>

            <Box>
              <Typography>{offer.waypoints[0].neighborhood}</Typography>
              <Typography variant="caption" color="textSecondary">
                {offer.waypoints[0].city}
              </Typography>
            </Box>
          </Box>

          {hasExtraSteps(offer) && (
            <Box display="flex" pb={2} alignItems="center">
              <Box
                width="100px"
                pt={0.25}
                pr={pxToRem(27)}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box
                  width="20px"
                  height="20px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor={colors.blue[500]}
                  color={colors.root[0]}
                  onClick={handleStateRouteDetails}
                >
                  +
                </Box>
              </Box>

              <Box>
                <Typography variant="body1">
                  {parseExtraSteps(offer)}
                </Typography>
              </Box>
              <Backdrop
                className={classes.backdrop}
                open={isRouteDetailsOpen}
                onClick={handleStateRouteDetails}
                data-testid="offer-detail"
              >
                <OfferDetail
                  offer={offer}
                  onAccept={onAccept}
                  onReject={onReject}
                  hasReject={hasReject}
                />
              </Backdrop>
            </Box>
          )}

          <Box display="flex" alignItems="center">
            <Box
              width="100px"
              pt={0.25}
              pr={3}
              textAlign="right"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography variant="body1" color="textSecondary">
                {TEXT.END}:
              </Typography>

              <RoundListMarker
                bgcolor={colors.blue[500]}
                txtcolor={colors.root[0]}
                index={offer.waypoints[offer.waypoints.length - 1].indexDisplay}
              />
            </Box>

            <Box>
              <Typography>
                {offer.waypoints[offer.waypoints.length - 1].neighborhood}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {offer.waypoints[offer.waypoints.length - 1].city}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

OfferPricing.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    recommended: PropTypes.bool,
    deadlineCollect: PropTypes.string.isRequired,
    directAttribution: PropTypes.bool,
    shipperName: PropTypes.string,
    tagMessage: PropTypes.string,
    tagColor: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,

  // (itineraryId) => {}
  onAccept: PropTypes.func.isRequired,

  onReject: PropTypes.func,

  hasReject: PropTypes.bool,

  originDistance: PropTypes.string.isRequired
};

OfferPricing.defaultProps = {
  onReject: () => null,
  hasReject: false
};

export default React.memo(OfferPricing);
