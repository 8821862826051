import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { pxToRem } from '@loggi/mar/src/utils';
import constants from './constants';

import { ReactComponent as EmptyListImage } from '../../images/empty_image.svg';
import { ReactComponent as DisabledImage } from '../../images/disabled_image.svg';

const EmptyListComponent = ({ disabled }) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="center"
      flexGrow={2}
    >
      {disabled ? (
        <DisabledImage
          width={pxToRem(54)}
          height={pxToRem(54)}
          data-testid="disabled-img"
        />
      ) : (
        <EmptyListImage
          width={pxToRem(54)}
          height={pxToRem(54)}
          data-testid="empty-img"
        />
      )}

      <Box>
        <Typography variant="subtitle1">
          <Box py="12px" fontWeight="fontWeightMedium" fontSize={pxToRem(18)}>
            {disabled
              ? constants.DISABLED_LIST_TITLE
              : constants.EMPTY_LIST_TITLE}
          </Box>
        </Typography>
      </Box>
      <Box textAlign="center" px={5}>
        <Typography variant="body2">
          {disabled
            ? constants.DISABLED_LIST_SUBTITLE
            : constants.EMPTY_LIST_SUBTITLE}
        </Typography>
      </Box>
    </Box>
  );
};

EmptyListComponent.propTypes = {
  disabled: PropTypes.bool
};

EmptyListComponent.defaultProps = {
  disabled: false
};

export default EmptyListComponent;
