import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogContent,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';

const ErrorAlert = ({ error, onClose }) => (
  <Dialog open>
    <DialogTitle id="alert-dialog-title">Ocorreu um erro</DialogTitle>
    <DialogContent>
      <DialogContentText>{error}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Ok, entendi</Button>
    </DialogActions>
  </Dialog>
);

ErrorAlert.propTypes = {
  error: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default React.memo(ErrorAlert);
