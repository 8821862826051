import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { parseISODateToHours } from 'view/molecules/offer-card/util';
import { OfferTag } from './common';

function OfferDirect({ offer, originDistance }) {
  const shipperInfo = () => {
    let message = '';

    if (offer.shipperName) {
      message = offer.shipperName.concat(` • `);
    }
    return (
      <Box fontWeight="fontWeightBold" display="inline">
        {message}
      </Box>
    );
  };

  const deadlineInfo = () => {
    let message = '';

    const deadline = parseISODateToHours(offer.deadlineCollect);

    if (deadline) {
      message = message.concat(`Ir hoje, até ${deadline}`);
    }

    return (
      <Typography style={{ fontWeight: 600 }} variant="body1">
        {message}
      </Typography>
    );
  };

  return (
    <Box>
      <Box p={2.5} textAlign="left" pb={8}>
        <OfferTag message={offer.tagMessage} bgcolor={offer.tagColor} mb={2} />
        <Typography variant="h4">
          Paradas adicionadas
          <Box fontWeight="fontWeightBold">na sua lista!</Box>
        </Typography>
      </Box>

      <Box
        p={2.5}
        justifyContent="space-evenly"
        textAlign="left"
        alignItems="left"
        pb={10}
      >
        <Box pb={1}>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold">
              {offer.waypoints.length} paradas
            </Box>
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="body1">
            {shipperInfo()}
            {offer.waypoints[0].neighborhood}, {offer.waypoints[0].city}
          </Typography>
          {deadlineInfo()}
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary">
            {originDistance} de você
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

OfferDirect.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    deadlineCollect: PropTypes.string,
    shipperName: PropTypes.string,
    tagMessage: PropTypes.string,
    tagColor: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,

  originDistance: PropTypes.string.isRequired
};

export default React.memo(OfferDirect);
