import { getDistance } from 'geolib';
import geolocation from './geolocation';

/**
 * Get distance of current location until coordinates
 *
 * @param {Coordinates} coordinates
 * @returns {Promise<number>} - Numeric distance between the passed coordinates to current position
 * @reject - When cant get current position
 */
export default async coordinates =>
  geolocation()
    .then(position => getDistance(position, coordinates))
    .catch(() => Promise.reject());
