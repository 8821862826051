import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import { ReactComponent as DeviceBlockedIcon } from 'view/assets/svg/device-blocked.svg';
import LoadingTemplate from 'view/templates/loading';

export const forbiddenDevicePageTexts = {
  title: 'Você não está autorizado a utilizar esse celular',
  subtitle:
    'Caso queira trocar o aparelho celular que você utiliza, entre em contato com o nosso atendimento.',
  suport: 'Fale com a gente',
  linkSuport:
    'https://ajuda.loggi.com/hc/pt-br/requests/new?ticket_form_id=160657',
  logout: 'Sair da conta'
};

export default function ForbiddenDevicePage() {
  const [isUiLoading, setIsUILoading] = useState(false);
  const { signOut } = useAmplifyAuth();

  const logout = async () => {
    setIsUILoading(true);
    await signOut();
  };

  if (isUiLoading) {
    return <LoadingTemplate />;
  }

  return (
    <Box pt={8} p={3} height="100%" display="flex" flexDirection="column">
      <Box flexGrow={1}>
        <DeviceBlockedIcon />
        <Box my={1}>
          <Typography variant="h5">{forbiddenDevicePageTexts.title}</Typography>
        </Box>
        <Typography variant="subtitle2">
          {forbiddenDevicePageTexts.subtitle}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => window.open(forbiddenDevicePageTexts.linkSuport)}
        >
          {forbiddenDevicePageTexts.suport}
        </Button>
        <Box pt={2} pb={3} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            onClick={logout}
          >
            {forbiddenDevicePageTexts.logout}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
