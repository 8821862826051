import api from 'infra/api';

async function logOfferPresented(offer) {
  return api()
    .url('/api/v1/pedido/oferta-apresentada/')
    .post({ offer_uuid: offer.id })
    .text();
}

async function logOffersPresented(offers = []) {
  if (!offers || offers.length === 0) return Promise.resolve();

  return Promise.all(offers.map(logOfferPresented));
}

export default { logOffersPresented };
