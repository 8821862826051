import React from 'react';
import PropTypes from 'prop-types';
import theme from '@loggi/mar';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AuthenticationRoutes from './routes';

import 'view/styles/index.css';

export default function DriverAuthenticationRoutes({
  createAccountPath,
  onSuccessLoginRedirectPath
}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthenticationRoutes
        createAccountPath={createAccountPath}
        onSuccessLoginRedirectPath={onSuccessLoginRedirectPath}
      />
    </ThemeProvider>
  );
}

DriverAuthenticationRoutes.propTypes = {
  createAccountPath: PropTypes.string,
  onSuccessLoginRedirectPath: PropTypes.string
};

DriverAuthenticationRoutes.defaultProps = {
  createAccountPath: null,
  onSuccessLoginRedirectPath: null
};
