/**
 * This module serves the purpose of exposing the history
 * object to calls outside React componentes. Its use is
 * discouraged unless absolutely necessary. Always prefer
 * using `useHistory`.
 *
 * @see {How do I access the history object outside of components?}
 * https://github.com/ReactTraining/react-router/blob/master/FAQ.md#how-do-i-access-the-history-object-outside-of-components
 */
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;
