import React from 'react';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import { formState } from 'view/state-machines/utils/form';
import SignupLayoutTemplate from 'view/templates/signup-layout';
import LoginMachine from 'view/state-machines/login.machine';
import LoginTemplate from 'view/templates/login';
import LoadingTemplate from 'view/templates/loading';
import { pages } from 'routes';

export default function LoginPage() {
  const history = useHistory();
  const [current, send] = useMachine(LoginMachine);

  const onBack = () => history.push(pages.onboardingPage);

  if (current.matches(formState.success)) {
    history.push(pages.smsVerificationCode, { from: pages.login });
  }

  const component =
    current.value === formState.submitting ? (
      <LoadingTemplate />
    ) : (
      <LoginTemplate LoginMachine={{ current, send }} />
    );

  return <SignupLayoutTemplate page={component} onBack={onBack} />;
}
