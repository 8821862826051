export const PERMISSION_DENIED_MESSAGE =
  'Você não tem permissão para acessar este serviço, verifique com o administrador';
const NETWORK_ERROR_MESSAGE = 'Não conseguimos acessar o servidor';
const NOT_FOUND_MESSAGE = 'Endereço não encontrado';
const DEFAULT_MESSAGE_500 = 'Falha no servidor, tente novamente mais tarde';
const DEFAULT_MESSAGE_400 = 'Requisição inválida';

const PARSE_ERROR = {
  errors: [
    {
      message: 'Erro ao interpretar resposta'
    }
  ],
  code: 0
};

const NETWORK_ERROR = {
  errors: [
    {
      message: NETWORK_ERROR_MESSAGE
    }
  ],
  code: 0
};

const RESPONSE_MESSAGES_BY_CODE = {
  403: PERMISSION_DENIED_MESSAGE,
  404: NOT_FOUND_MESSAGE
};

function translateStatusCode(status) {
  if (status >= 400 && status < 500) {
    const message = RESPONSE_MESSAGES_BY_CODE[status] || DEFAULT_MESSAGE_400;

    return {
      errors: [{ message }],
      code: status
    };
  }

  if (status >= 500 && status < 600) {
    const message = RESPONSE_MESSAGES_BY_CODE[status] || DEFAULT_MESSAGE_500;

    return {
      errors: [{ message }],
      code: status
    };
  }

  return { errors: [], code: 0 };
}

export { PARSE_ERROR, NETWORK_ERROR, translateStatusCode };
