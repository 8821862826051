export const PERMISSION_DENIED_MESSAGE =
  'Você não tem permissão para acessar este serviço, verifique com o administrador';
export const NETWORK_ERROR_MESSAGE =
  'Erro ao acessar o servidor. Tente de novo.';
export const NOT_FOUND_MESSAGE = 'Endereço não encontrado';
export const DEFAULT_MESSAGE = 'Falha no sistema. Tente de novo.';

const getError = error => ({
  errors: [
    {
      message: error
    }
  ],
  code: 0
});

const getNetworkError = () => getError(NETWORK_ERROR_MESSAGE);
const getDefaultError = () => getError(DEFAULT_MESSAGE);

const RESPONSE_MESSAGES_BY_CODE = {
  403: PERMISSION_DENIED_MESSAGE,
  404: NOT_FOUND_MESSAGE,
  500: DEFAULT_MESSAGE,
  502: DEFAULT_MESSAGE,
  504: DEFAULT_MESSAGE
};

function translateStatusCode(status, message = '') {
  if (RESPONSE_MESSAGES_BY_CODE[status]) {
    const messageError = RESPONSE_MESSAGES_BY_CODE[status];

    return {
      errors: [{ message: messageError }],
      code: status
    };
  }

  if (status === 401) {
    try {
      return { errors: [JSON.parse(message)], code: status };
    } catch {
      return { errors: [message], code: status };
    }
  }

  if (message) {
    if (message instanceof String || typeof message === 'string') {
      const REMOVE_QUOTES = new RegExp(/('|")/g);
      const messageError = message.replace(REMOVE_QUOTES, '');
      return { errors: [{ message: messageError }], code: status };
    }

    return { errors: message, code: status };
  }

  return { errors: [], code: 0 };
}

export { getDefaultError, getNetworkError, translateStatusCode };
