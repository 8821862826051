import { getCognitoIdToken, getCredentials } from 'infra/services/login';
import { PARSE_ERROR, translateStatusCode } from './http-message';

async function parseResponse(response) {
  let responseBody;

  try {
    responseBody = await response.json();
  } catch (exception) {
    return Promise.reject(PARSE_ERROR);
  }

  if (response.ok) {
    return responseBody;
  }

  const errorByStatusCode = translateStatusCode(response.status);

  if (errorByStatusCode.errors.length > 0) {
    return Promise.reject(errorByStatusCode);
  }

  const errorBody = {
    ...responseBody,
    code: response.status
  };

  return Promise.reject(errorBody);
}

/**
 * @param {Object} request
 * @param {string} request.query - graphql query string
 * @param {Object} request.variables
 */
export default async function graphqlRequest({ query, variables }) {
  const url = `${process.env.REACT_APP_LOGGI_WEB_URL}/graphql/`;

  const headers = {
    'Content-Type': 'application/json'
  };

  const cognitoToken = await getCognitoIdToken();
  headers.Authorization = cognitoToken || getCredentials();

  const config = {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    headers
  };

  return fetch(url, config).then(parseResponse);
}
