import wretch from 'wretch';
import { dedupe } from 'wretch-middlewares';
import { getAuthorizationTokens } from '@loggi/authentication-lib';
import {
  getDefaultError,
  getNetworkError,
  translateStatusCode
} from '../request/http-message';

export async function getHeaders(headers) {
  const { idToken } = await getAuthorizationTokens();

  return {
    ...headers,
    Authorization: idToken
      ? `Bearer ${idToken}`
      : window?.driverAppBridge?.getLWApiKey?.()
  };
}

export const headerMiddleware = next => async (url, opts) => {
  const headers = opts?.headers;

  const optsHeader = {
    ...opts,
    headers: {
      appVersion: process.env.REACT_APP_VERSION,
      service: 'driver-authentication',
      ...(await getHeaders(headers))
    }
  };

  return next(url, optsHeader);
};

const dedupeMiddleware = dedupe({
  // Avoid send multiple identical requests before receiving a response
  // For now, we're using only for status-update endpoint
  skip: (url, opts) => opts.skipDedupe || !url.includes('validate-device'),
  key: (url, opts) => `${opts.method}@${url}`
});

export default function restRequest() {
  return wretch()
    .url(`${process.env.REACT_APP_LOGGI_WEB_URL}/`)
    .content('application/json')
    .middlewares([headerMiddleware, dedupeMiddleware])
    .catcher('Error', err =>
      Promise.reject(translateStatusCode(err.response.status, err.message))
    )
    .catcher('FetchError', () => Promise.reject(getDefaultError()))
    .catcher('__fromFetch', () => Promise.reject(getNetworkError()));
}
