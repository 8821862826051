import graphqlRequest from 'infra-operations/graphql-request';

const requestPhoneVerificationMutation = `
  mutation RequestPhoneVerificationMutation($mobileNumber: String!) {
    driverPhoneVerificationMutation(input: {mobileNumber: $mobileNumber}) {
      isSuccessfully: success
      errors {
        message
      }
    }
  }
`;

export default function requestPhoneVerification(variables) {
  return graphqlRequest({
    query: requestPhoneVerificationMutation,
    variables
  }).then(({ data }) => data.driverPhoneVerificationMutation);
}
