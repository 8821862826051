import restRequest from 'infra-operations/rest-request';
import { storeCurrentUserInfo } from 'infra-operations/storage/credential';
import { getDefaultError } from '../request/http-message';

const LOGIN_URL = 'drivers/v2/login-mobile-phone/';

export const loginCheck = formData => {
  return restRequest()
    .url(LOGIN_URL)
    .post(formData)
    .json()
    .then(({ driver }) => {
      if (driver && driver.api_key && driver.email) {
        storeCurrentUserInfo({
          apiKey: driver.api_key,
          email: driver.email
        });

        return driver;
      }

      return Promise.reject(getDefaultError());
    });
};

const DriverLogin = {
  loginCheck
};

export default DriverLogin;
