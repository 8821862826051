import { getAuthorizationTokens } from '@loggi/authentication-lib';
import { getCurrentUserInfo } from 'infra-operations/storage/credential';
import { isEmbeddedInDriverApp } from '@loggi/drivers-app-version';
import { parseResponse, handleError } from '../request';

/**
 * @param {Object} request
 * @param {string} request.query - graphql query string
 * @param {Object} request.variables
 */
export default async function graphqlRequest({ query, variables }) {
  const currentUser = getCurrentUserInfo();
  const { idToken } = await getAuthorizationTokens();

  const url = `${process.env.REACT_APP_LOGGI_WEB_URL}/graphql/`;

  const headers = {
    'Content-Type': 'application/json',
    appVersion: process.env.REACT_APP_VERSION,
    service: 'driver-authentication'
  };

  if (isEmbeddedInDriverApp() && window?.driverAppBridge?.getLWApiKey?.()) {
    headers.Authorization = window?.driverAppBridge?.getLWApiKey?.();
  } else if (idToken) {
    headers.Authorization = `Bearer ${idToken}`;
  }

  if (currentUser && currentUser.email) {
    headers.observabilityId = currentUser.email;
  }

  const config = {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    headers
  };

  return fetch(url, config)
    .then(parseResponse)
    .catch(handleError);
}
