export const invalidDriverAppVersion = 'Versão inválida';

export function isEmbeddedInDriverApp() {
  return window.driverAppBridge !== undefined;
}

export function isEmbeddedInDriverAppWithWebHome() {
  return window.driverAppBridge?.isFromNewHome?.();
}

function getDriverAppVersion() {
  if (!isEmbeddedInDriverApp()) return 'Web';

  return window.driverAppBridge?.getAppVersion?.() || invalidDriverAppVersion;
}

export function hasValidDriverAppVersion(minVersion) {
  if (!minVersion || !isEmbeddedInDriverAppWithWebHome()) {
    return true;
  }

  if (
    !getDriverAppVersion() ||
    getDriverAppVersion() === invalidDriverAppVersion
  ) {
    return false;
  }

  // this might return NaN. when comparing with a number, NaN
  // will return false, which is the behavior we want
  const currentVersion = parseInt(getDriverAppVersion(), 10);
  return currentVersion >= minVersion;
}

export function hasNewDriverAppVersion() {
  if (!isEmbeddedInDriverAppWithWebHome()) {
    return false;
  }

  if (window.driverAppBridge?.hasNewDriverAppVersion !== undefined) {
    return window.driverAppBridge?.hasNewDriverAppVersion?.();
  }

  return true;
}

function getTargetVersion() {
  const envVersion = process.env.REACT_APP_VERSION || 'dev';
  if (envVersion === 'dev') {
    return 'dev';
  }

  // envVersion is a string that follows our deployment
  // tag, e.g. flecha/prod-20000623.01(/beta)
  // the output of this function should be:
  // v01(/beta) • 23/06/2000
  const splitByDash = envVersion.split('-');
  const dateAndVersion = splitByDash[1];

  const date = dateAndVersion.split('.')[0];
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  const formattedDate = `${day}/${month}/${year}`;

  const version = dateAndVersion.split('.')[1];
  const formattedVersion = `v${version}`;

  return `${formattedVersion} • ${formattedDate}`;
}

export default function getAppVersion() {
  const driverAppVersion = getDriverAppVersion();
  const targetVersion = getTargetVersion();
  return `Versão do app: ${driverAppVersion} • ${targetVersion}`;
}
