import React from 'react';
import { Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@material-ui/core';
import theme from '@loggi/mar';
import { ThemeProvider } from '@material-ui/core/styles';
import { AmplifyAuthProvider } from '@loggi/authentication-lib';
import FirebaseConfigProvider from '@loggi/firebase-feature-switches';
import * as Sentry from '@sentry/browser';
import Routes from 'view/routes';

import OffersPageV2 from 'view/pages/offer-screen/v2';
import OfferScreen from 'view/pages/offer-screen';
import PrivateRoute from 'view/atoms/private-route';
import UnauthenticatedPage from 'view/pages/unauthenticated';

import 'typeface-open-sans';
import 'view/index.css';

export const PAGES = {
  OFFER_LIST: '/',
  OFFER_LIST_V2: '/v2',
  OFFER_SCREEN: '/ofertas/:offerId',
  UNAUTHENTICATED: '/unauthenticated'
};

class App extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <AmplifyAuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <FirebaseConfigProvider>
            <SnackbarProvider
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'top'
              }}
            >
              <Routes>
                <Route
                  exact
                  path={PAGES.UNAUTHENTICATED}
                  component={UnauthenticatedPage}
                />
                <PrivateRoute
                  exact
                  path={PAGES.OFFER_LIST}
                  component={OffersPageV2}
                />
                <PrivateRoute
                  exact
                  path={PAGES.OFFER_LIST_V2}
                  component={OffersPageV2}
                />
                <PrivateRoute
                  path={PAGES.OFFER_SCREEN}
                  component={OfferScreen}
                />
                <Route path="*" component={() => <h1>Page not found</h1>} />
              </Routes>
            </SnackbarProvider>
          </FirebaseConfigProvider>
        </ThemeProvider>
      </AmplifyAuthProvider>
    );
  }
}

export default App;
